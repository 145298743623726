/**
 * Destaca as palavras-chave de um bloco de texto
 * @param {String} string String do parágrafo
 * @param {String} keyWord Palavra-chave
 * @param {String} class Classe que será retornada dentro do parágrafo
 * @param {String} indexElement Número do elemento que deve ser selecionado
 *
 * @returns {String}
 */

export const highlight = (
  string,
  keyWord,
  className = 'highlight',
  indexElement = null
) => {
  const reg = new RegExp(`[$|^$|.|,| |;|"|']${keyWord}[$|^$|.|,| |;|"|']`, 'gi')

  /** Contador de marcadores */
  let index = 0
  return string.replace(reg, (match) => {
    index++
    return !indexElement || indexElement === index
      ? `<span class="${className}">` + match + '</span>'
      : match
  })
}

/**
 * Cria um arquivo a partir de uma string
 *
 * @param {String} data - Conteúdo do arquivo
 * @param {String} name - Nome do arquivo
 */

export const downloadFile = (data, name = 'download.txt') => {
  const element = document.createElement('a')
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(data)
  )
  element.setAttribute('download', name)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}

/**
 * Padroniza string para o formato de telefone
 *
 * @param {String} number - String do numero do telefone
 */

export const phoneFormat = (number) => {
  if (!number) return ''
  number = number.replace(/\D/g, '')
  number = number.replace(/(\d{2})(\d)/, '($1) $2')
  number = number.replace(/(\d)(\d{4})$/, '$1-$2')

  return number
}
