var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fade"}},[(_vm.value)?_c('div',{staticClass:"modal-component"},[_c('div',{staticClass:"modal-component--content"},[_c('div',{staticClass:"modal-component--header"},[_c('Heading',{staticClass:"modal-component--title",attrs:{"size":"10","weight":"medium"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('font-awesome-icon',{staticClass:"modal-component--close",attrs:{"icon":"fa-solid fa-circle-xmark","size":"lg"},on:{"click":function($event){return _vm.$emit('input', false)}}})],1),_c('div',{staticClass:"modal-component--body"},[(_vm.description)?_c('div',{staticClass:"modal-component--container"},[_c('Paragraph',{staticClass:"modal-component--description",attrs:{"size":"60"}},[_c('span',{domProps:{"innerHTML":_vm._s(
                _vm.highlight(
                  _vm.formatDescription,
                  _vm.query,
                  'modal-component--highlight',
                  _vm.indexSearch
                )
              )}})]),_c('Button',{staticClass:"modal-component--download",on:{"click":function($event){return _vm.download()}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-download"}}),_vm._v(" download ")],1)],1):_c('div',{staticClass:"modal-component--container"},[_vm._t("default")],2)])]),_c('div',{staticClass:"modal-component--overlay",on:{"click":function($event){return _vm.$emit('input', false)}}})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }