export default ($axios) => ({
  /**
   * Cria um tipo de parecer
   * @param { Object } data: "apreciacaoId", "caae", "pesquisadorId", "membroCepId", "tipoParecerId", "situacaoParecerId", "dataSubmissao", "dataParecer" para criar um tipo de parecer
   */
  create: (data) =>
    $axios.post('/parecer', {
      ...data
    }),

  /**
   * Cria um parecer a partir de um arquivo CSV
   * @param {Object} data
   */
  upload: (data) =>
    $axios.post('/parecer/csv', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }),

  /**
   * Atualizar um tipo de parecer
   * @param { Number } id: "id" do tipo de parecer
   * @param { Object } data: "apreciacaoId", "caae", "pesquisadorId", "membroCepId", "tipoParecerId", "situacaoParecerId", "dataSubmissao", "dataParecer" para editar um tipo de parecer
   */
  update: (id, data) =>
    $axios.put(`/parecer/${id}`, {
      ...data
    }),

  /**
   * Busca os tipo de parecer
   * @param { Object } data: "id" "direcao" "tamanhoPagina" "nome" "ordenarPor" e "pagina" para a consulta
   */
  get: (data) =>
    $axios.get(`/parecer/${data?.id ?? ''}`, {
      params: {
        ...data
      }
    }),

  /** Remove um tipo de parecer */
  delete: (id = '') => $axios.delete(`/parecer/${id}`)
})
