export default ($axios) => ({
  /**
   * Cria um pesquisador
   * @param { Object } data: "nome" para criar um pesquisador
   */
  create: (data) =>
    $axios.post('/pesquisador', {
      ...data
    }),

  /**
   * Atualizar um pesquisador
   * @param { Number } id: "id" do pesquisador
   * @param { Object } data: "nome" para editar um pesquisador
   */
  update: (id, data) =>
    $axios.put(`/pesquisador/${id}`, {
      ...data
    }),

  /**
   * Busca os pesquisador
   * @param { Object } data: "id" "direcao" "tamanhoPagina" "nome" "ordenarPor" e "pagina" para a consulta
   */
  get: (data) =>
    $axios.get(`/pesquisador/${data?.id ?? ''}`, {
      params: {
        ...data
      }
    }),

  /** Remove um pesquisador */
  delete: (id = '') => $axios.delete(`/pesquisador/${id}`)
})
