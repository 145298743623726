export default ($axios) => ({
  /**
   * Cria um tipo de parecer
   * @param { Object } data: "nome" para criar um tipo de parecer
   */
  create: (data) =>
    $axios.post('/parecer/tipo', {
      ...data
    }),

  /**
   * Atualizar um tipo de parecer
   * @param { Number } id: "id" do tipo de parecer
   * @param { Object } data: "nome" para editar um tipo de parecer
   */
  update: (id, data) =>
    $axios.put(`/parecer/tipo/${id}`, {
      ...data
    }),

  /**
   * Busca os tipo de parecer
   * @param { Object } data: "id" "direcao" "tamanhoPagina" "nome" "ordenarPor" e "pagina" para a consulta
   */
  get: (data) =>
    $axios.get(`/parecer/tipo/${data?.id ?? ''}`, {
      params: {
        ...data
      }
    }),

  /** Remove um tipo de parecer */
  delete: (id = '') => $axios.delete(`/parecer/tipo/${id}`)
})
