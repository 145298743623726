import Vue from 'vue'
import VueRouter from 'vue-router'
import { getCurrentUserAccess } from '@/helpers/auth'

/** Telas */
import Login from '@/views/Login'

/** Telas administrativas */
import Admin from '@/views/admin/index'
import Users from '@/views/admin/Users'
import Researcher from '@/views/admin/Researcher'
import Opinion from '@/views/admin/Opinion'
import TypeOfOpinion from '@/views/admin/TypeOfOpinion'
import SituationOfOpinion from '@/views/admin/SituationOfOpinion'
import Appreciation from '@/views/admin/Appreciation'
import MemberCep from '@/views/admin/MemberCep'
import Cep from '@/views/admin/Cep'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresNotAuth: true
    }
  },
  {
    path: '/',
    name: 'Admin',
    component: Admin,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cep',
    name: 'Cep',
    component: Cep,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/researcher',
    name: 'Researcher',
    component: Researcher,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/opinion',
    name: 'Opinion',
    component: Opinion,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/typeofopinion',
    name: 'TypeOfOpinion',
    component: TypeOfOpinion,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/situationofopinion',
    name: 'SituationOfOpinion',
    component: SituationOfOpinion,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/appreciation',
    name: 'Appreciation',
    component: Appreciation,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/membercep',
    name: 'MemberCep',
    component: MemberCep,
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_PATH,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

const DEFAULT_TITLE = 'Painel do Cep'
router.beforeEach((to, from, next) => {
  /**
   * Use next tick to handle router history correctly
   * see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
   */

  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE
    /** Se requer autenticação */
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      const token = getCurrentUserAccess()
      if (!token) {
        window.location = '/login'
        return false
      } else {
        next()
      }
    }
    /** Se não requer autenticação */
    if (to.matched.some((record) => record.meta.requiresNotAuth)) {
      const token = getCurrentUserAccess()
      if (token) {
        /** Redireciona para a home do letsbook antigo */
        this.$router.push({ path: '/' })
        return false
      } else {
        next()
      }
    }

    next()
  })
})

export default router
