var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-admin"},[_c('div',{staticClass:"menu-admin--header"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"header--logo--image",attrs:{"src":_vm.logo}})])],1),_c('div',{staticClass:"menu-admin--links"},[_c('router-link',{staticClass:"menu-admin--links__item",class:{ 'menu-admin--links__item__active': _vm.active === 'Início' },attrs:{"to":"/"}},[_c('Paragraph',{attrs:{"size":"40","weight":"medium"}},[_vm._v("INÍCIO")])],1),_c('router-link',{staticClass:"menu-admin--links__item",class:{
        'menu-admin--links__item__active': _vm.active === 'CEPs'
      },attrs:{"to":"/cep"}},[_c('Paragraph',{attrs:{"size":"40","weight":"medium"}},[_vm._v("CEPs")])],1),_c('router-link',{staticClass:"menu-admin--links__item",class:{
        'menu-admin--links__item__active': _vm.active === 'Pesquisadores'
      },attrs:{"to":"/researcher"}},[_c('Paragraph',{attrs:{"size":"40","weight":"medium"}},[_vm._v("PESQUISADORES")])],1),_c('router-link',{staticClass:"menu-admin--links__item",class:{
        'menu-admin--links__item__active': _vm.active === 'Pareceres'
      },attrs:{"to":"/opinion"}},[_c('Paragraph',{attrs:{"size":"40","weight":"medium"}},[_vm._v("PARECERES")])],1),_c('router-link',{staticClass:"menu-admin--links__item",class:{
        'menu-admin--links__item__active': _vm.active === 'Tipos de pareceres'
      },attrs:{"to":"/typeofopinion"}},[_c('Paragraph',{attrs:{"size":"40","weight":"medium"}},[_vm._v("TIPOS DE PARECERES")])],1),_c('router-link',{staticClass:"menu-admin--links__item",class:{
        'menu-admin--links__item__active': _vm.active === 'Situações de pareceres'
      },attrs:{"to":"/situationofopinion"}},[_c('Paragraph',{attrs:{"size":"40","weight":"medium"}},[_vm._v("SITUAÇÕES DE PARECERES")])],1),_c('router-link',{staticClass:"menu-admin--links__item",class:{
        'menu-admin--links__item__active': _vm.active === 'Apreciações'
      },attrs:{"to":"/appreciation"}},[_c('Paragraph',{attrs:{"size":"40","weight":"medium"}},[_vm._v("APRECIAÇÕES")])],1),_c('router-link',{staticClass:"menu-admin--links__item",class:{
        'menu-admin--links__item__active': _vm.active === 'Membros do CEP'
      },attrs:{"to":"/membercep"}},[_c('Paragraph',{attrs:{"size":"40","weight":"medium"}},[_vm._v("MEMBROS DO CEP")])],1),(_vm.getRole && _vm.getRole.includes('ADMIN'))?_c('router-link',{staticClass:"menu-admin--links__item",class:{ 'menu-admin--links__item__active': _vm.active === 'Usuários' },attrs:{"to":"/users"}},[_c('Paragraph',{attrs:{"size":"40","weight":"medium"}},[_vm._v("USUÁRIOS")])],1):_vm._e()],1),_c('div',{staticClass:"menu-admin--footer"},[_c('Paragraph',{staticClass:"footer--rights",attrs:{"size":"20","weight":"medium"}},[_vm._v(" ©2023 Todos os direitos reservados ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }