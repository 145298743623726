<template>
  <div class="home">
    <AdminLayout page="Início">
      <template #content>
        <div class="home--header">
          <!-- IMAGEM DE FUNDO -->
          <img :src="background" class="home--background" />
          <!-- TÍTULO -->
          <div class="home--info">
            <div>
              <Heading class="home--title" weight="medium" size="80"
                >PAINEL CEP</Heading
              >
              <Heading class="home--subtitle" weight="medium" size="20"
                >BEM-VINDO</Heading
              >
            </div>
          </div>
        </div>

        <!-- IFRAME - TÉCNICO -->
        <iframe
          v-if="getRole && getRole.includes('TECNICO')"
          title="TECNICO_Painel_SE.CONEP"
          width="100%"
          height="541.25"
          src="https://app.powerbi.com/reportEmbed?reportId=6ae68832-f106-4bbd-881b-ff509809893a&autoAuth=true&ctid=9a554ad3-b52b-4862-a36f-84d891e5c705"
          frameborder="0"
          allowFullScreen="true"
        ></iframe>

        <!-- IFRAME - GESTOR -->
        <iframe
          v-else-if="getRole && getRole.includes('GESTOR')"
          title="GESTOR_Painel_SE.CONEP"
          width="100%"
          height="541.25"
          src="https://app.powerbi.com/reportEmbed?reportId=495d5343-825b-4d85-be53-1f00b50157ae&autoAuth=true&ctid=9a554ad3-b52b-4862-a36f-84d891e5c705"
          frameborder="0"
          allowFullScreen="true"
        ></iframe>

        <!-- IFRAME - ADMINISTRADOR -->
        <iframe
          v-else-if="getRole && getRole.includes('ADMIN')"
          title="ADM_Painel_SE.CONEP"
          width="100%"
          height="541.25"
          src="https://app.powerbi.com/reportEmbed?reportId=7a15f8b6-59a9-4578-a750-c1366425a86e&autoAuth=true&ctid=9a554ad3-b52b-4862-a36f-84d891e5c705"
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      </template>
    </AdminLayout>
  </div>
</template>

<script>
import AdminLayout from '@/layouts/AdminLayout'
import Heading from '@/components/Heading'

import background from '@/assets/images/background-admin.png'

import { mapGetters } from 'vuex'

export default {
  name: 'Admin',

  components: {
    AdminLayout,
    Heading
  },

  computed: {
    ...mapGetters('user', ['getRole'])
  },

  data() {
    return {
      /** Imagem de background */
      background: background
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  ::v-deep {
    .admin-layout--container {
      padding: 0px;
    }
  }

  &--header {
    height: 250px;
    position: relative;
    overflow: hidden;
    padding: 35px;

    display: flex;
    justify-content: flex-start;
    flex-direction: column-reverse;
  }

  &--background {
    position: absolute;
    top: 0%;
    right: 0%;
    min-height: 100%;
    min-width: 100%;
    z-index: -1;
  }

  &--title {
    color: $white;
    margin-bottom: 5px;
  }

  &--subtitle {
    color: $white;
    margin-bottom: 0px;
  }

  &--info {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}
</style>
