/**
 * Configura o axios, faz uma injeção de dependencia no Vue
 * e exporta como um plugin para ser usado globalmente
 */

import axios from 'axios'
import * as rax from 'retry-axios'
import store from '../store/index'
import { deleteCookie, getAuthCookieName } from '../helpers/auth'
import { errorMessageHandler } from '../helpers/errors'

const contextsCache = {}
const servicesContext = require.context(
  '!@/services' /** procura no diretório */,
  true /** busca em subdiretórios */,
  /\.js$/i /** somento arquivos .js */
)

function importAll(contextsArray) {
  contextsArray
    .keys()
    .forEach((key) => (contextsCache[key] = contextsArray(key)))
}

importAll(servicesContext)

const endpoints = axios.create({
  baseURL: process.env.VUE_APP_URI,
  timeout: 30000,
  withCredentials: true,
  xsrfHeaderName: 'X-CSRF-TOKEN',
  headers: {
    common: {
      Accept: 'application/json, text/plain, */*',
      Authorization: `${store.getters.getAuthToken}`
    }
  }
})

/**
 * Adiciona o retry ao instâcia do axios
 * @see https://github.com/JustinBeckwith/retry-axios
 */
endpoints.defaults.raxConfig = {
  instance: endpoints,
  retry: 10,
  noResponseRetries: 10,
  statusCodesToRetry: [
    [100, 199],
    [429, 429],
    [500, 599]
  ],
  onRetryAttempt: (err) => {
    const cfg = rax.getConfig(err)
    store.dispatch(
      'setRetryDelay',
      ((Math.pow(2, cfg.currentRetryAttempt) - 1) / 2) * 1000
    )
  }
}
rax.attach(endpoints)

/** Atualizar o token csrf */
function updateCsrf(token = null) {
  axios.defaults.headers.common['X-CSRF-TOKEN'] = token
  axios.defaults.headers.common['CSRF-TOKEN'] = token
  axios.defaults.headers.common['X-XSRF-TOKEN'] = token
  axios.defaults.headers.common['XSRF-TOKEN'] = token
  endpoints.defaults.headers.common['X-CSRF-TOKEN'] = token
  endpoints.defaults.headers.common['CSRF-TOKEN'] = token
  endpoints.defaults.headers.common['X-XSRF-TOKEN'] = token
  endpoints.defaults.headers.common['XSRF-TOKEN'] = token
  endpoints.defaults.headers['CSRF-TOKEN'] = token
  endpoints.defaults.headers['X-CSRF-TOKEN'] = token
  endpoints.defaults.headers['XSRF-TOKEN'] = token
  endpoints.defaults.headers['X-XSRF-TOKEN'] = token

  store.dispatch('setCsrf', token)
  document.cookie = 'XSRF-TOKEN=' + token
  document.cookie = 'X-XSRF-TOKEN=' + token
  document.cookie = 'CSRF-TOKEN=' + token
  document.cookie = 'X-CSRF-TOKEN=' + token
}

/** Métodos customizados */
const onError = (error) => {
  /** Setar Token CSRF */
  if (error.response && error.response.headers['x-csrf-token']) {
    const csrf = error.response.headers['x-csrf-token']
    updateCsrf(csrf)
  }

  const endpoint = error.config.url
  const code = parseInt(error.response && error.response.status)
  const errorMessages = errorMessageHandler(error)

  /** Se não foi autorizado porque o token expirou */
  if (code === 401 || (code === 403 && endpoint !== '/auth/signin')) {
    /** Exclui o cookie */
    deleteCookie(getAuthCookieName())
    /** Limpa o token da store */
    store.dispatch('setAuthToken', null)
    /** Seta a flag de token expirado */
    store.dispatch('setIsTokenExpired', true)
    /** Redirecionar para a tela de login */
    if (endpoint !== '/auth/signin') window.location = '/login'
  }

  return Promise.reject({ ...errorMessages, ...error })
}

const onResponse = (response) => {
  /** Setar Token CSRF */
  if (response && response.headers['x-csrf-token']) {
    const csrf = response.headers['x-csrf-token']
    updateCsrf(csrf)
  }

  /** Remove a mensagem de retry se existir */
  if (response.status === 200) {
    store.dispatch('setRetryDelay', null)
  }

  /** se o token expirou e está logado */
  if (
    response.status === 200 &&
    response.headers['token-expired'] &&
    JSON.parse(response.headers['token-expired']) &&
    store.getters['config/getLogged']
  ) {
    /** Exclui o cookie */
    deleteCookie(getAuthCookieName())

    /** Limpa o token da store */
    store.dispatch('setAuthToken', null)

    /** Seta a flag de token expirado */
    store.dispatch('setIsTokenExpired', true)
  }

  return response
}

/** Adiciona um interceptador na resposta */
endpoints.interceptors.response.use(onResponse, onError)

const Services = {}

Services.install = function(Vue) {
  Object.keys(contextsCache).forEach((key) => {
    const service = contextsCache[key].default(endpoints)
    /** extrai o nome do serviço, usa o mesmo nome do arquivo .js */
    const name = key.replace(/^\.\/(.*)\.\w+$/, '$1')

    Vue.prototype[`$${name}`] = service
  })
}

export default Services
