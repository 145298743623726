<template>
  <div class="admin-layout">
    <div class="admin-layout--menu">
      <MenuAdmin :active="page" />
    </div>
    <div class="admin-layout--content">
      <div class="admin-layout--navbar">
        <!-- BRADCRUMP -->
        <Paragraph size="40" weight="medium">
          Você está em: <strong>{{ page }}</strong>
        </Paragraph>

        <!-- USUÁRIO -->
        <div class="admin-layout--user">
          <!-- DROPDOWN DE USUÁRIO -->
          <Dropdown class="admin-layout--dropdown">
            <template v-slot:button>
              <font-awesome-icon
                class="admin-layout--dropdown__icon"
                icon="fa-solid fa-circle-user"
              />

              <Paragraph size="40" weight="medium">
                {{ getFirstName }}
              </Paragraph>
            </template>

            <template v-slot:items>
              <li @click="edit()">
                <Paragraph size="40" weight="medium">
                  <font-awesome-icon icon="fa-solid fa-pen-to-square" /> Editar
                </Paragraph>
              </li>

              <li @click="editPassword()">
                <Paragraph size="40" weight="medium">
                  <font-awesome-icon icon="fa-solid fa-key" /> Redefinir a senha
                </Paragraph>
              </li>

              <li @click="signOut()">
                <Paragraph size="40" weight="medium">
                  <font-awesome-icon icon="fa-solid fa-right-from-bracket" />
                  Sair
                </Paragraph>
              </li>
            </template>
          </Dropdown>
        </div>
      </div>
      <!-- EDITAR USUÁRIO -->
      <Modal v-model="user.edit" title="EDITAR USUÁRIO" class="modal-edit">
        <!-- NOME DO USUÁRIO -->
        <FormInput
          v-model="user.primeiroNome"
          :border="true"
          label="Nome"
          placeholder="Insira o Nome"
          class="modal-edit--input"
        />

        <!-- SOBRENOME DO USUÁRIO -->
        <FormInput
          v-model="user.ultimoNome"
          :border="true"
          label="Sobrenome"
          placeholder="Insira o Sobrenome"
          class="modal-edit--input"
        />

        <!-- E-MAIL DO USUÁRIO -->
        <FormInput
          v-model="user.email"
          :border="true"
          type="email"
          label="E-mail"
          placeholder="Insira o E-mail"
          class="modal-edit--input"
        />

        <div class="modal-edit--row">
          <!-- BOTÃO DE SALVAR -->
          <Button
            :radius="false"
            class="modal-edit--button"
            @click="saveUser()"
          >
            SALVAR
          </Button>
        </div>
      </Modal>

      <!-- REDEFINIR SENHA -->
      <Modal
        v-model="user.editPassword"
        title="REDEFINIR SENHA"
        class="modal-edit"
      >
        <!-- SENHA DO USUÁRIO -->
        <FormInput
          v-model="user.senha"
          :border="true"
          type="password"
          label="Senha"
          placeholder="Insira a Senha"
          class="modal-edit--input"
        />
        <!-- CONFIRMAR A SENHA -->
        <FormInput
          v-model="user.confirmPassword"
          :border="true"
          type="password"
          label="Confirmar a senha"
          placeholder="Confirmar a senha"
        />
        <div class="modal-edit--row">
          <!-- BOTÃO DE SALVAR -->
          <Button
            :radius="false"
            class="modal-edit--button"
            @click="savePassword()"
          >
            SALVAR
          </Button>
        </div>
      </Modal>
      <div class="admin-layout--container">
        <!-- CONTEÚDO -->
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Paragraph from '@/components/Paragraph'
import Dropdown from '@/components/Dropdown'
import Button from '@/components/Button'
import MenuAdmin from '@/components/_base/MenuAdmin'
import Modal from '@/components/Modal'
import FormInput from '@/components/FormInput'

import { mapActions, mapGetters } from 'vuex'

import { deleteCookie, getAuthCookieName } from '../helpers/auth'

export default {
  name: 'AdminLayout',

  components: {
    MenuAdmin,
    Paragraph,
    Dropdown,
    Button,
    Modal,
    FormInput
  },

  props: {
    /** Nome da página */
    page: {
      type: String,
      default: 'Página'
    }
  },

  data() {
    return {
      /** Usuário a ser editado */
      user: {
        primeiroNome: null /** Nome do usuário */,
        ultimoNome: null /** Sobrenome do usuário */,
        email: null /** E-mail do usuário */,
        senha: null /** Senha do usuário */,
        confirmPassword: null /** Confirmação de senha */,
        edit: false /** Visibilidade da modal de edição */,
        editPassword: false /** Visibilidade da modal de redefinição de senha */
      }
    }
  },

  computed: {
    ...mapGetters('user', ['getFirstName', 'getLastName', 'getEmail'])
  },

  methods: {
    /** Deslogar o usuário */
    signOut() {
      /** Exclui o cookie */
      deleteCookie(getAuthCookieName())

      /** Limpa o token da store */
      this.$store.dispatch('setAuthToken', null)

      /** Seta a flag de token expirado */
      this.$store.dispatch('setIsTokenExpired', true)

      /** Redirecionar para a tela de login */
      window.location = '/login'
    },

    /** Abrir a modal de edição do usuário */
    edit() {
      /** Pegar os dados do usuário e abrir a modal, verificar se o usuário é o mesmo logado */
      this.user.edit = true
      this.user.primeiroNome = this.getFirstName
      this.user.ultimoNome = this.getLastName
      this.user.email = this.getEmail
      this.user.senha = null
      this.user.confirmPassword = null
    },

    /** Abrir a modal de redefinição de senha */
    editPassword() {
      /** Pegar os dados do usuário e abrir a modal, verificar se o usuário é o mesmo logado */
      this.user.editPassword = true
      this.user.senha = null
      this.user.confirmPassword = null
    },

    /** Fechar a modal de edição de usuário */
    closeModalUser() {
      this.user.edit = false
      this.user.editPassword = false
      this.user.primeiroNome = null
      this.user.ultimoNome = null
      this.user.email = null
      this.user.senha = null
      this.user.confirmPassword = null
    },

    /** Salvar o usuário */
    saveUser() {
      this.$user
        .update({
          primeiroNome: this.user.primeiroNome,
          ultimoNome: this.user.ultimoNome,
          email: this.user.email
        })
        .then(() => {
          this.$toast.success('Usuário atualizado com sucesso!')
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao atualizar o usuário.')
        })
        .finally(() => {
          /** Fechar a modal */
          this.closeModalUser()
          /** Recarregar os dados do usuário */
          this.requestUserData()
        })
    },

    /** Redefinir senha */
    savePassword() {
      /** Confirmar a senha */
      if (this.user.senha !== this.user.confirmPassword)
        return this.$toast.warning('Houve um erro ao confirmar a nova senha!')

      this.$user
        .update({
          senha: this.user.senha
        })
        .then(() => {
          this.$toast.success('Senha atualizado com sucesso!')
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao atualizar a sua senha.')
        })
        .finally(() => {
          /** Fechar a modal */
          this.closeModalUser()
          /** Recarregar os dados do usuário */
          this.requestUserData()
        })
    },

    /**
     * Esconde a lista de opções ao selecionar uma opção
     * @param {Event} e: Evento da ação
     */
    hideOptionsOnBlur(e) {
      /** Se click foco foi para um filho mantém visível */
      if (!this.$el.contains(e.relatedTarget)) {
        this.hideOptionsOnBlur = false
      }
    },

    /** Recarregar os dados do usuário */
    ...mapActions('user', ['requestUserData'])
  }
}
</script>

<style lang="scss" scoped>
.admin-layout {
  min-height: 100vh;
  width: 100%;
  display: flex;

  &--menu {
    width: 100%;
    max-width: 195px;

    @media screen and (max-width: 950px) {
      max-width: 120px;
    }
  }

  &--content {
    flex: 1;
    min-width: 750px;
  }

  &--navbar {
    background-color: $gray;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .paragraph {
      color: $grayDark;
      margin-bottom: 0px;
    }
  }

  &--dropdown {
    cursor: pointer;
    position: relative;

    &__icon {
      font-size: 20px;
      color: $primary;
    }
  }

  &--container {
    padding: 20px;
  }
}

/** MODAL DE EDIÇÃO */
.modal-edit {
  ::v-deep {
    .paragraph {
      line-height: 20px;
    }
    .modal-component--content {
      max-width: 600px;
    }
  }

  &--row {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;

    margin-top: 20px;
  }

  &--button {
    display: block;
  }
}
</style>
