<template>
  <transition name="fade">
    <div v-if="value" class="modal-component">
      <!-- CONTEÚDO DA MODAL -->
      <div class="modal-component--content">
        <div class="modal-component--header">
          <Heading size="10" weight="medium" class="modal-component--title">
            {{ title }}
          </Heading>

          <!-- BOTÃO PARA FECHAR -->
          <font-awesome-icon
            icon="fa-solid fa-circle-xmark"
            size="lg"
            class="modal-component--close"
            @click="$emit('input', false)"
          />
        </div>

        <div class="modal-component--body">
          <!-- SE HOUVER UMA DESCRIÇÃO -->
          <div v-if="description" class="modal-component--container">
            <!-- DESCRIÇÃO DA MODAL -->
            <Paragraph size="60" class="modal-component--description">
              <span
                v-html="
                  highlight(
                    formatDescription,
                    query,
                    'modal-component--highlight',
                    indexSearch
                  )
                "
              ></span>
            </Paragraph>

            <!-- BOTÃO DE DOWNLOAD -->
            <Button class="modal-component--download" @click="download()">
              <font-awesome-icon icon="fa-solid fa-download" /> download
            </Button>
          </div>

          <!-- SE NÃO HOUVER UMA DESCRIÇÃO, USAR O SLOT -->
          <div v-else class="modal-component--container">
            <slot></slot>
          </div>
        </div>
      </div>

      <!-- OVERLAY -->
      <div
        class="modal-component--overlay"
        @click="$emit('input', false)"
      ></div>
    </div>
  </transition>
</template>

<script>
import Heading from '@/components/Heading'
import Paragraph from '@/components/Paragraph'
import Button from '@/components/Button'

import { highlight, downloadFile } from '@/helpers/string'

/** Componente padrão de modais */
export default {
  name: 'Modal',

  components: {
    Heading,
    Paragraph,
    Button
  },

  props: {
    /** Visibilidade da modal */
    value: {
      type: Boolean,
      default: false
    },

    /** Título */
    title: {
      type: String,
      default: 'Título'
    },

    /** Texto da modal */
    description: {
      type: [String, Array],
      default: null
    },

    /** Palavra-chave */
    query: {
      type: String,
      default: ''
    },

    /** Index da palavra-chave */
    indexSearch: {
      type: [String, Number],
      default: ''
    }
  },

  computed: {
    /** Formatar dados para exibição */
    formatDescription() {
      return Array.isArray(this.description)
        ? this.description.join(' <br />')
        : this.description
    },

    /** Formatar dados para download */
    formatDescriptionDownload() {
      return Array.isArray(this.description)
        ? this.description.join('\r\n')
        : this.description
    }
  },

  methods: {
    /** Fazer o download do arquivo */
    download() {
      return downloadFile(this.formatDescriptionDownload, `${this.title}.txt`)
    },

    highlight
  }
}
</script>

<style lang="scss" scoped>
.modal-component {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10;
  padding: 0px 25px;

  display: flex;
  align-items: center;
  justify-content: center;

  &--content {
    width: 100%;
    max-width: $maxWidth;
    max-height: 90vh;
    overflow: hidden;
    border-radius: 10px;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 0px auto;

    @media screen and (max-width: 768px) {
      max-height: 80vh;
    }
  }

  &--header {
    background: $primary;
    padding: 20px 30px;
    position: relative;
  }

  &--close {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    color: $white;
    cursor: pointer;
  }

  &--title {
    color: $white;
    margin-bottom: 0px;
  }

  &--body {
    background: $white;
    padding: 30px;
    flex: 1;
    overflow-x: auto;
  }

  &--description {
    line-height: 20px;
  }

  &--download {
    .paragraph {
      color: inherit;
    }
  }

  &--overlay {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .paragraph ::v-deep {
    .modal-component--highlight {
      background: $tertiary;
    }
  }
}

/** Transições */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
