<template>
  <div class="home">
    <AdminLayout page="Membros do CEP">
      <template #content>
        <!-- TÍTULO -->
        <Heading size="20" weight="bold" class="title">Cadastrar</Heading>

        <!-- FORMULÁRIO DE CADASTRO DE MEMBROS DO CEP -->
        <div class="form">
          <!-- CAMPO DO NOME -->
          <FormInput v-model="nome" placeholder="Nome" class="form--input" />

          <!-- GENERO DO MEMBRO DO CEP -->
          <FormSelect
            v-model="genero"
            :options="generoOptions"
            placeholder="Selecione o Gênero"
            class="form--input"
          />

          <!-- BOTÃO DE ENVIAR -->
          <Button
            :radius="false"
            class="form--button"
            @click="createMemberCep()"
          >
            CADASTRAR
          </Button>
        </div>

        <!-- TABELA -->
        <Datatable
          :colsUpper="datatable.gridColumnsUpper"
          :rows="datatable.data"
          :numberElements="datatable.numberElements"
          :numberPages="datatable.numberPages"
          :page="datatable.page"
          :hasSearch="true"
          title="Membros do CEP cadastrados"
          termResults="resultados encontrados"
          class="datatable"
          @changePagination="paginationCallback"
          @changeOrdering="orderCallback"
          @search="searchCallback"
        >
          <!-- GÊNEROS -->
          <template v-slot:genero="slotProps">
            <Paragraph size="60" weight="normal">{{
              slotProps.row.generoNome
            }}</Paragraph>
          </template>

          <!-- COLUNA DE AÇÕES -->
          <template v-slot:action="slotProps">
            <div class="datatable--actions">
              <!-- EDITAR MEMBRO DO CEP -->
              <font-awesome-icon
                class="datatable--icon"
                icon="fa-solid fa-pen-to-square"
                @click="edit(slotProps.row.id)"
              />

              <!-- REMOVER MEMBRO DO CEP -->
              <font-awesome-icon
                class="datatable--icon datatable--icon__remove"
                icon="fa-solid fa-trash-can"
                @click="dialogMemberCep(slotProps.row.id)"
              />
            </div>
          </template>
        </Datatable>

        <!-- EDITAR MEMBRO DO CEP -->
        <Modal
          v-model="memberCep.edit"
          title="EDITAR MEMBRO DO CEP"
          class="modal-edit__edit"
        >
          <!-- NOME DO MEMBRO DO CEP -->
          <FormInput
            v-model="memberCep.nome"
            :border="true"
            placeholder="Nome da Membro do cep"
            class="modal-edit--input"
          />

          <!-- GENERO DO MEMBRO DO CEP -->
          <FormSelect
            v-model="memberCep.genero"
            :options="generoOptions"
            placeholder="Selecione o Gênero"
            class="form--input"
          />

          <div class="modal-edit--row">
            <!-- BOTÃO DE SALVAR -->
            <Button
              :radius="false"
              class="modal-edit--button"
              @click="saveMemberCep()"
            >
              GRAVAR
            </Button>
          </div>
        </Modal>

        <!-- MODAL DE CONFIRMAÇÃO DE REMOÇÃO DE MEMBRO DO CEP -->
        <Modal
          v-model="memberCep.delete"
          title="REMOVER MEMBRO DO CEP"
          class="modal-edit__remove"
        >
          <Paragraph size="60" weight="normal">
            Tem certeza que deseja remover o membro do cep
            <strong>{{ memberCep.nome }}?</strong>
          </Paragraph>

          <div class="modal-edit--row">
            <!-- BOTÃO DE CANCELAR -->
            <Button
              :radius="false"
              color="outline"
              class="modal-edit--button"
              @click="closeModalMemberCep()"
            >
              CANCELAR
            </Button>

            <!-- BOTÃO DE DELETAR -->
            <Button
              :radius="false"
              color="danger"
              class="modal-edit--button"
              @click="deleteMemberCep()"
            >
              DELETAR
            </Button>
          </div>
        </Modal>
      </template>
    </AdminLayout>
  </div>
</template>

<script>
import AdminLayout from '@/layouts/AdminLayout'
import Heading from '@/components/Heading'
import Paragraph from '@/components/Paragraph'
import FormInput from '@/components/FormInput'
import FormSelect from '@/components/FormSelect'
import Button from '@/components/Button'
import Datatable from '@/components/Datatable'
import Modal from '@/components/Modal'

export default {
  name: 'MemberCep',

  components: {
    AdminLayout,
    Heading,
    Paragraph,
    FormInput,
    FormSelect,
    Button,
    Datatable,
    Modal
  },

  data() {
    return {
      /** Dados do membro do cep */
      nome: null,
      genero: null,

      /** Gêneros */
      generoOptions: [
        { id: 1, label: 'Masculino' },
        { id: 2, label: 'Feminino' }
      ],

      /** Tabela */
      datatable: {
        gridColumnsUpper: [
          { name: 'id', alias: 'ID' },
          { name: 'nome', alias: 'Nome', order: true },
          { name: 'genero', alias: 'Gênero', order: true },
          { name: 'action', alias: 'Ação' }
        ],
        search: null,
        data: [],
        numberElements: 0,
        numberPages: 0,
        page: 1,
        limitPerPage: 50,
        order: null,
        direction: null
      },

      /** Membro do cep a ser editado */
      memberCep: {
        id: null /** ID do membro do cep */,
        nome: null /** Nome do membro do cep */,
        genero: null /** Gênero do membro do cep */,
        edit: false /** Visibilidade da modal de edição */,
        delete: false /** Visibilidade da modal de remoção */
      }
    }
  },

  created() {
    /** Busca os membros do cep */
    this.getMembersCep()
  },

  methods: {
    /** Buscar os membros do cep */
    getMembersCep() {
      this.$memberCep
        .get({
          nome: this.datatable.search,
          direcao: this.datatable.direction,
          tamanhoPagina: this.datatable.limitPerPage,
          ordenarPor: this.datatable.order,
          pagina: this.datatable.page
        })
        .then((response) => {
          const membersCep = response.data.content.map((memberCep) => {
            return {
              id: memberCep.id,
              nome: memberCep.nome,
              genero: memberCep.genero,
              generoNome:
                memberCep.genero === 1
                  ? 'Masculino'
                  : memberCep.genero === 2
                  ? 'Feminino'
                  : '-'
            }
          })
          this.datatable.data = membersCep
          this.datatable.numberElements = response.data.totalElements
          this.datatable.numberPages = response.data.totalPages
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao apresentar os membros do cep.')
        })
    },

    /** Criar uma membro do cep */
    createMemberCep() {
      this.$memberCep
        .create({ nome: this.nome, genero: this.genero?.id })
        .then(() => {
          /** Feedback de sucesso */
          this.$toast.success('Membro do cep criado com sucesso!')
        })
        .catch(() => {
          /** Feedback de erro */
          this.$toast.error('Houve um erro ao criar o membro do cep!')
        })
        .finally(() => {
          /** Limpar os campos */
          this.nome = null
          this.genero = null

          /** Recarregar a lista */
          this.getMembersCep()
        })
    },

    /**
     * Paginação
     * @param {Number} page: Número da página selecionada
     */
    paginationCallback(page) {
      this.datatable.page = page
      this.getMembersCep()
    },

    /**
     * Ordenação
     * @param {Object} data: Objeto de dados da ordenação
     */
    orderCallback(data) {
      this.datatable.order = data.order
      this.datatable.direction = data.direction
      this.getMembersCep()
    },

    /**
     * Pesquisa da tabela
     * @param {Object} data: Objeto de dados da ordenação
     */
    searchCallback(search) {
      this.datatable.page = 1
      this.datatable.search = search
      this.getMembersCep()
    },

    /**
     * Exibir a modal de edição de membro do cep
     * @param {Number} id: Id do membro do cep
     */
    edit(id) {
      /** Localizar o membro do cep na listagem */
      const memberCep = this.datatable.data.find((item) => item.id === id)

      /** Pegar os dados do membro do cep e abrir a modal */
      if (memberCep) {
        this.memberCep.edit = true
        this.memberCep.id = memberCep.id
        this.memberCep.nome = memberCep.nome
        this.memberCep.genero = {
          id: memberCep.genero,
          label: memberCep.generoNome
        }
      } else {
        this.$toast.warning('Houve um erro ao encontrar o membro do cep.')
        this.closeModalMemberCep()
      }
    },

    /**
     * Confirmar se deseja remover o membro do cep
     * @param {Number} id: Id do membro do cep
     */
    dialogMemberCep(id) {
      /** Localizar o membro do cep na listagem */
      const memberCep = this.datatable.data.find((item) => item.id === id)

      /** Pegar os dados do membro do cep e abrir a modal de confirmação */
      if (memberCep) {
        this.memberCep.delete = true
        this.memberCep.id = memberCep.id
        this.memberCep.nome = memberCep.nome
        this.memberCep.genero = memberCep.genero
      } else {
        this.$toast.warning('Houve um erro ao encontrar o membro do cep.')
        this.closeModalMemberCep()
      }
    },

    /** Fechar a modal do membro do cep */
    closeModalMemberCep() {
      this.memberCep.edit = false
      this.memberCep.delete = false
      this.memberCep.nome = null
      this.memberCep.genero = null
    },

    /** Atualizar o membro do cep */
    saveMemberCep() {
      this.$memberCep
        .update(this.memberCep.id, {
          nome: this.memberCep.nome,
          genero: this.memberCep.genero?.id
        })
        .then(() => {
          this.$toast.success('Membro do cep atualizado com sucesso!')
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao atualizar o membro do cep.')
        })
        .finally(() => {
          /** Fechar a modal */
          this.closeModalMemberCep()

          /** Recarregar a listagem */
          this.getMembersCep()
        })
    },

    /** Remover o membro do cep */
    deleteMemberCep() {
      this.$memberCep
        .delete(this.memberCep.id)
        .then(() => {
          this.$toast.success('Membro do cep removido com sucesso!')
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao remover o membro do cep.')
        })
        .finally(() => {
          /** Fechar a modal */
          this.closeModalMemberCep()

          /** Recarregar a listagem */
          this.getMembersCep()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
}
.form {
  display: flex;
  align-items: flex-end;
  background: $gray;
  padding: 7px;
  border-radius: 5px;
  gap: 7px;
  margin-bottom: 25px;

  &--input {
    margin: 0px;
  }

  &--button {
    margin: 0px;
  }
}

.datatable {
  &--link {
    cursor: pointer;
    text-decoration: underline;
  }

  ::v-deep {
    thead th:nth-child(1),
    tr td:nth-child(1) {
      width: 70px;
      text-align: center;
    }

    thead th:nth-child(4),
    tr td:nth-child(4) {
      width: 120px;
    }
  }

  &--actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  &--icon {
    color: $primary;
    text-align: center;
    cursor: pointer;

    &__remove {
      color: $danger;
    }
  }
}

/** MODAL DE EDIÇÃO DO MEMBRO DO CEP */
.modal-edit {
  ::v-deep {
    .paragraph {
      line-height: 20px;
    }
  }

  &--row {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;
  }

  &--button {
    display: block;
  }

  &__edit {
    ::v-deep {
      .modal-component--content {
        max-width: 600px;
      }
    }
  }

  &__remove {
    ::v-deep {
      .modal-component--content {
        max-width: 500px;
      }
    }
  }
}
</style>
