<template>
  <div class="home">
    <AdminLayout page="CEPs">
      <template #content>
        <!-- TÍTULO -->
        <Heading size="20" weight="bold" class="title">Cadastrar</Heading>

        <!-- FORMULÁRIO DE CADASTRO DE CEPS -->
        <div class="form">
          <!-- CAMPO DO NOME -->
          <FormInput
            v-model="nome"
            label="Nome"
            placeholder="Nome do CEP"
            class="form--input"
          />

          <!-- CAMPO DO E-MAIL -->
          <FormInput
            v-model="email"
            type="email"
            label="E-mail"
            placeholder="Insira o E-mail"
            class="form--input"
          />

          <!-- CAMPO DO TELEFONE -->
          <FormInput
            v-model="telefone"
            :hasMask="true"
            :mask="['(##) ####-####', '(##) #####-####']"
            label="Telefone"
            placeholder="(##) #####-####"
            class="form--input"
          />

          <!-- REGIÃO -->
          <FormSelect
            v-model="regiao"
            :options="regiaoOptions"
            :showLabel="true"
            label="Região"
            placeholder="Selecione a Região"
            class="form--input"
          />

          <!-- UF -->
          <FormSelect
            v-model="uf"
            :options="ufOptions"
            :showLabel="true"
            label="UF"
            placeholder="Selecione o UF"
            class="form--input"
          />

          <!-- MUNICÍPIO -->
          <FormInput
            v-model="municipio"
            label="Município"
            placeholder="Insira o Município"
            class="form--input"
          />

          <!-- STATUS -->
          <FormSelect
            v-model="status"
            :options="statusOptions"
            :showLabel="true"
            label="Status"
            placeholder="Selecione o Status"
            class="form--input"
          />

          <!-- CAMPO DO NÚMERO DE MEMBROS -->
          <FormInput
            v-model="membros"
            type="number"
            label="Nº de membros"
            placeholder="000"
            class="form--input"
          />

          <!-- CAMPO DO NÚMERO DE MEMBROS MASCULINOS -->
          <FormInput
            v-model="membrosSexoMasculino"
            type="number"
            label="Nº de membros do sexo masculinos"
            placeholder="000"
            class="form--input"
          />

          <!-- CAMPO DO NÚMERO DE MEMBROS MASCULINOS -->
          <FormInput
            v-model="membrosSexoFeminino"
            type="number"
            label="Nº de membros do sexo feminino"
            placeholder="000"
            class="form--input"
          />

          <!-- BOTÃO DE ENVIAR -->
          <Button :radius="false" class="form--button" @click="createCep()">
            CADASTRAR
          </Button>
        </div>

        <!-- TÍTULO FORMULÁRIO EXCEL -->
        <Heading size="20" weight="bold" class="title"
          >Cadastrar por um arquivo CSV</Heading
        >

        <!-- FORMULÁRIO DE CADASTRO DE CEPS POR EXCEL -->
        <div class="form form__csv-file">
          <!-- CAMPO DO ARQUIVO DE UPLOAD DE CEP -->
          <FormInputFile
            v-model="fileCSV"
            :showLabel="true"
            label="Arquivo CSV"
            class="form--input"
          />

          <!-- BOTÃO DE ENVIAR -->
          <Button :radius="false" class="form--button" @click="uploadFile()">
            ENVIAR
          </Button>
        </div>

        <!-- TABELA -->
        <Datatable
          :colsUpper="datatable.gridColumnsUpper"
          :rows="datatable.data"
          :numberElements="datatable.numberElements"
          :numberPages="datatable.numberPages"
          :page="datatable.page"
          :hasSearch="true"
          title="CEPs cadastrados"
          termResults="resultados encontrados"
          class="datatable"
          @changePagination="paginationCallback"
          @changeOrdering="orderCallback"
          @search="searchCallback"
        >
          <!-- COLUNA DE AÇÕES -->
          <template v-slot:action="slotProps">
            <div class="datatable--actions">
              <!-- EDITAR CEP -->
              <font-awesome-icon
                class="datatable--icon"
                icon="fa-solid fa-pen-to-square"
                @click="edit(slotProps.row.id)"
              />

              <!-- REMOVER CEP -->
              <font-awesome-icon
                class="datatable--icon datatable--icon__remove"
                icon="fa-solid fa-trash-can"
                @click="dialogCep(slotProps.row.id)"
              />
            </div>
          </template>
        </Datatable>

        <!-- EDITAR CEP -->
        <Modal v-model="cep.edit" title="EDITAR CEP" class="modal-edit__edit">
          <div class="form__edit">
            <!-- CAMPO DO NOME -->
            <FormInput
              v-model="cep.nome"
              :border="true"
              label="Nome"
              placeholder="Insira o Nome"
              class="form--input"
            />

            <!-- CAMPO DO E-MAIL -->
            <FormInput
              v-model="cep.email"
              :border="true"
              type="email"
              label="E-mail"
              placeholder="Insira o E-mail"
              class="form--input"
            />

            <!-- CAMPO DO TELEFONE -->
            <FormInput
              v-model="cep.telefone"
              :hasMask="true"
              :mask="['(##) ####-####', '(##) #####-####']"
              :border="true"
              label="Telefone"
              placeholder="(##) #####-####"
              class="form--input"
            />

            <!-- REGIÃO -->
            <FormSelect
              v-model="cep.regiao"
              :options="regiaoOptions"
              :showLabel="true"
              label="Região"
              placeholder="Selecione a Região"
              class="form--input"
            />

            <!-- UF -->
            <FormSelect
              v-model="cep.uf"
              :options="ufOptions"
              :showLabel="true"
              label="UF"
              placeholder="Selecione o UF"
              class="form--input"
            />

            <!-- MUNICÍPIO -->
            <FormInput
              v-model="cep.municipio"
              :border="true"
              label="Município"
              placeholder="Insira o Município"
              class="form--input"
            />

            <!-- STATUS -->
            <FormSelect
              v-model="cep.status"
              :options="statusOptions"
              :showLabel="true"
              label="Status"
              placeholder="Selecione o Status"
              class="form--input"
            />

            <!-- CAMPO DO NÚMERO DE MEMBROS -->
            <FormInput
              v-model="cep.membros"
              :border="true"
              type="number"
              label="Nº de membros"
              placeholder="000"
              class="form--input"
            />

            <!-- CAMPO DO NÚMERO DE MEMBROS MASCULINOS -->
            <FormInput
              v-model="cep.membrosSexoMasculino"
              :border="true"
              type="number"
              label="Nº de membros do sexo masculinos"
              placeholder="000"
              class="form--input"
            />

            <!-- CAMPO DO NÚMERO DE MEMBROS MASCULINOS -->
            <FormInput
              v-model="cep.membrosSexoFeminino"
              :border="true"
              type="number"
              label="Nº de membros do sexo feminino"
              placeholder="000"
              class="form--input"
            />
          </div>

          <div class="modal-edit--row">
            <!-- BOTÃO DE SALVAR -->
            <Button
              :radius="false"
              class="modal-edit--button"
              @click="saveCep()"
            >
              GRAVAR
            </Button>
          </div>
        </Modal>

        <!-- MODAL DE CONFIRMAÇÃO DE REMOÇÃO DE CEP -->
        <Modal
          v-model="cep.delete"
          title="REMOVER CEP"
          class="modal-edit__remove"
        >
          <Paragraph size="60" weight="normal">
            Tem certeza que deseja remover o CEP?
          </Paragraph>

          <div class="modal-edit--row">
            <!-- BOTÃO DE CANCELAR -->
            <Button
              :radius="false"
              color="outline"
              class="modal-edit--button"
              @click="closeModalCep()"
            >
              CANCELAR
            </Button>

            <!-- BOTÃO DE DELETAR -->
            <Button
              :radius="false"
              color="danger"
              class="modal-edit--button"
              @click="deleteCep()"
            >
              DELETAR
            </Button>
          </div>
        </Modal>
      </template>
    </AdminLayout>
  </div>
</template>

<script>
import AdminLayout from '@/layouts/AdminLayout'
import Heading from '@/components/Heading'
import Paragraph from '@/components/Paragraph'
import FormInput from '@/components/FormInput'
import FormSelect from '@/components/FormSelect'
import FormInputFile from '@/components/FormInputFile'
import Button from '@/components/Button'
import Datatable from '@/components/Datatable'
import Modal from '@/components/Modal'

import { phoneFormat } from '@/helpers/string'

export default {
  name: 'Cep',

  components: {
    AdminLayout,
    Heading,
    Paragraph,
    FormInput,
    FormSelect,
    Button,
    Datatable,
    Modal,
    FormInputFile
  },

  data() {
    return {
      /** Dados do CEP */
      nome: null,
      email: null,
      telefone: null,
      regiao: null,
      uf: null,
      municipio: null,
      status: null,
      membros: null,
      membrosSexoFeminino: null,
      membrosSexoMasculino: null,
      fileCSV: null,

      /** Regiões */
      regiaoOptions: [
        { id: 1, label: 'NORDESTE' },
        { id: 2, label: 'NORTE' },
        { id: 3, label: 'CENTRO_OESTE' },
        {
          id: 4,
          label: 'SUDESTE'
        },
        { id: 5, label: 'SUL' }
      ],

      /** Lista de UFs */
      ufOptions: [],

      /** Status */
      statusOptions: [
        {
          label: 'Ativo',
          id: true
        },
        {
          label: 'Inativo',
          id: false
        }
      ],

      /** Tabela */
      datatable: {
        gridColumnsUpper: [
          { name: 'id', alias: 'ID' },
          { name: 'nome', alias: 'Nome do CEP', order: true },
          { name: 'email', alias: 'E-mail', order: true },
          { name: 'telefone', alias: 'Telefone', order: true },
          { name: 'regiao', alias: 'Região', order: true },
          { name: 'uf', alias: 'UF', order: true },
          { name: 'municipio', alias: 'Município', order: true },
          { name: 'membros', alias: 'Nº de Membros', order: true },
          { name: 'membrosSexoMasculino', alias: 'Nº Masc', order: true },
          { name: 'membrosSexoFeminino', alias: 'Nº Fem', order: true },
          { name: 'status', alias: 'Status', order: true },
          { name: 'action', alias: 'Ação' }
        ],
        data: [],
        search: null,
        numberElements: 0,
        numberPages: 0,
        page: 1,
        limitPerPage: 20,
        order: null,
        direction: null
      },

      /** CEP a ser editado */
      cep: {
        id: null /** ID do CEP */,
        nome: null,
        email: null,
        telefone: null,
        regiao: null,
        uf: null,
        municipio: null,
        status: null,
        membros: null,
        membrosSexoFeminino: null,
        membrosSexoMasculino: null,
        edit: false /** Visibilidade da modal de edição */,
        delete: false /** Visibilidade da modal de remoção */
      }
    }
  },

  created() {
    /** Busca os UFs */
    this.getStates()

    /** Busca os CEPs */
    this.getCeps()
  },

  methods: {
    /** Busca os UFs */
    getStates() {
      this.$cep
        .getUfs()
        .then((response) => {
          this.ufOptions = response.data.map((uf) => {
            return {
              id: uf.sigla,
              label: uf.sigla
            }
          })
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao buscar os estados.')
        })
    },

    /** Buscar os CEPs */
    getCeps() {
      this.$cep
        .get({
          termo: this.datatable.search,
          direcao: this.datatable.direction,
          tamanhoPagina: this.datatable.limitPerPage,
          ordenarPor: this.datatable.order,
          pagina: this.datatable.page
        })
        .then((response) => {
          const ceps = response.data.content.map((cep) => {
            return {
              id: cep.id,
              nome: cep.nome,
              email: cep.email,
              telefone: phoneFormat(cep.telefone),
              regiao: cep.regiao.nome,
              regiaoId: cep.regiao.id,
              uf: cep.uf,
              municipio: cep.municipio,
              status: cep.ativo ? 'Ativo' : 'Inativo',
              statusId: cep.ativo,
              membros: cep.membros,
              membrosSexoMasculino: cep.membrosSexoMasculino,
              membrosSexoFeminino: cep.membrosSexoFeminino
            }
          })

          this.datatable.data = ceps
          this.datatable.numberElements = response.data.totalElements
          this.datatable.numberPages = response.data.totalPages
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao apresentar os CEPs.')
        })
    },

    /** Criar um CEP */
    createCep() {
      this.$cep
        .create({
          nome: this.nome,
          email: this.email,
          telefone: this.telefone?.replace(/\D/g, ''),
          regiaoId: this.regiao?.id,
          uf: this.uf?.id,
          municipio: this.municipio,
          ativo: this.status?.id,
          membros: parseInt(this.membros),
          membrosSexoFeminino: parseInt(this.membrosSexoFeminino),
          membrosSexoMasculino: parseInt(this.membrosSexoMasculino)
        })
        .then(() => {
          /** Feedback de sucesso */
          this.$toast.success('CEP criado com sucesso!')
        })
        .catch(() => {
          /** Feedback de erro */
          this.$toast.error('Houve um erro ao criar a CEP!')
        })
        .finally(() => {
          /** Limpar o campo do nome */
          this.clearFields()

          /** Recarregar a lista */
          this.getCeps()
        })
    },

    /** Criar CEP por arquivo CSV */
    uploadFile() {
      const fieldsCEP = new FormData()
      fieldsCEP.append('file', this.fileCSV)

      this.$cep
        .upload(fieldsCEP)
        .then(() => {
          /** Feedback de sucesso */
          this.$toast.success('Arquivo enviado com sucesso!')
        })
        .catch(() => {
          /** Feedback de erro */
          this.$toast.error('Houve um erro ao enviar o Arquivo!')
        })
        .finally(() => {
          /** Limpar os campos */
          this.clearFields()

          /** Recarregar a lista */
          this.getCeps()
        })
    },

    /**
     * Paginação
     * @param {Number} page: Número da página selecionada
     */
    paginationCallback(page) {
      this.datatable.page = page
      this.getCeps()
    },

    /**
     * Ordenação
     * @param {Object} data: Objeto de dados da ordenação
     */
    orderCallback(data) {
      this.datatable.order = data.order
      this.datatable.direction = data.direction
      this.getCeps()
    },

    /** Limpar os campos */
    clearFields() {
      this.nome = null
      this.email = null
      this.telefone = null
      this.regiao = null
      this.uf = null
      this.municipio = null
      this.status = null
      this.id = null
      this.nome = null
      this.email = null
      this.telefone = null
      this.regiao = null
      this.uf = null
      this.municipio = null
      this.status = null
      this.membros = null
      this.membrosSexoFeminino = null
      this.membrosSexoMasculino = null
      this.fileCSV = null
    },

    /**
     * Pesquisa da tabela
     * @param {Object} data: Objeto de dados da ordenação
     */
    searchCallback(search) {
      this.datatable.page = 1
      this.datatable.search = search
      this.getCeps()
    },

    /**
     * Exibir a modal de edição de CEP
     * @param {Number} id: Id do CEP
     */
    edit(id) {
      /** Localizar o CEP na listagem */
      const cep = this.datatable.data.find((item) => item.id === id)

      /** Pegar os dados do CEP e abrir a modal */
      if (cep) {
        this.cep.edit = true
        this.cep.id = cep.id
        this.cep.nome = cep.nome
        this.cep.email = cep.email
        this.cep.telefone = cep.telefone
        this.cep.regiao = {
          id: cep.regiaoId,
          label: cep.regiao
        }
        this.cep.uf = {
          id: cep.uf,
          label: cep.uf
        }
        this.cep.municipio = cep.municipio
        this.cep.status = {
          id: cep.statusId,
          label: cep.status
        }
        this.cep.membros = cep.membros
        this.cep.membrosSexoFeminino = cep.membrosSexoFeminino
        this.cep.membrosSexoMasculino = cep.membrosSexoMasculino
      } else {
        this.$toast.warning('Houve um erro ao encontrar o CEP.')
        this.closeModalCep()
      }
    },

    /**
     * Confirmar se deseja remover o CEP
     * @param {Number} id: Id do CEP
     */
    dialogCep(id) {
      /** Localizar o CEP na listagem */
      const cep = this.datatable.data.find((item) => item.id === id)

      /** Pegar os dados do CEP e abrir a modal de confirmação */
      if (cep) {
        this.cep.delete = true
        this.cep.id = cep.id
        this.cep.nome = cep.nome
      } else {
        this.$toast.warning('Houve um erro ao encontrar o CEP.')
        this.closeModalCep()
      }
    },

    /** Fechar a modal do CEP */
    closeModalCep() {
      this.cep.edit = false
      this.cep.delete = false
    },

    /** Atualizar o CEP */
    saveCep() {
      this.$cep
        .update(this.cep.id, {
          nome: this.cep.nome,
          email: this.cep.email,
          telefone: this.cep.telefone?.replace(/\D/g, ''),
          regiaoId: this.cep.regiao?.id,
          uf: this.cep.uf?.id,
          membros: parseInt(this.cep.membros),
          membrosSexoFeminino: parseInt(this.cep.membrosSexoFeminino),
          membrosSexoMasculino: parseInt(this.cep.membrosSexoMasculino),
          municipio: this.cep.municipio,
          ativo: this.cep.status?.id
        })
        .then(() => {
          this.$toast.success('CEP atualizado com sucesso!')
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao atualizar o CEP.')
        })
        .finally(() => {
          /** Fechar a modal */
          this.closeModalCep()

          /** Limpar o campo do nome */
          this.clearFields()

          /** Recarregar a listagem */
          this.getCeps()
        })
    },

    /** Remover o CEP */
    deleteCep() {
      this.$cep
        .delete(this.cep.id)
        .then(() => {
          this.$toast.success('CEP removido com sucesso!')
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao remover o CEP.')
        })
        .finally(() => {
          /** Fechar a modal */
          this.closeModalCep()

          /** Recarregar a listagem */
          this.getCeps()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
}
.form {
  display: flex;
  align-items: flex-end;
  background: $gray;
  padding: 7px;
  border-radius: 5px;
  gap: 7px;
  margin-bottom: 25px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  &--input {
    margin: 0px;
  }

  &--button {
    margin: 0px;
  }

  &__edit {
    display: grid;
    gap: 7px;
    grid-template-columns: 1fr 1fr;
  }

  &__csv-file {
    grid-template-columns: 2fr 1fr;
  }
}

.datatable {
  &--link {
    cursor: pointer;
    text-decoration: underline;
  }

  ::v-deep {
    thead th:nth-child(1),
    tr td:nth-child(1) {
      width: 50px;
      text-align: center;
    }

    thead th:nth-child(4),
    tr td:nth-child(4) {
      width: 110px;
      text-align: center;
    }

    .datatable--td .paragraph {
      font-size: paragraph-size('paragraph-40');
    }

    .datatable--heading {
      font-size: paragraph-size('paragraph-20');
    }
  }

  &--actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  &--icon {
    color: $primary;
    text-align: center;
    cursor: pointer;

    &__remove {
      color: $danger;
    }
  }
}

/** MODAL DE EDIÇÃO DO CEP */
.modal-edit {
  ::v-deep {
    .paragraph {
      line-height: 20px;
    }
  }

  &--row {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;
  }

  &--button {
    display: block;
  }

  &__edit {
    ::v-deep {
      .modal-component--content {
        max-width: 600px;
      }
    }
  }

  &__remove {
    ::v-deep {
      .modal-component--content {
        max-width: 500px;
      }
    }
  }
}
</style>
