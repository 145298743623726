<template>
  <div class="home">
    <AdminLayout page="Situações de pareceres">
      <template #content>
        <!-- TÍTULO -->
        <Heading size="20" weight="bold" class="title">Cadastrar</Heading>

        <!-- FORMULÁRIO DE CADASTRO DE SITUAÇÕES DE PARECERES -->
        <div class="form">
          <!-- CAMPO DO NOME -->
          <FormInput v-model="nome" placeholder="Nome" class="form--input" />

          <!-- BOTÃO DE ENVIAR -->
          <Button
            :radius="false"
            class="form--button"
            @click="createSituation()"
          >
            CADASTRAR
          </Button>
        </div>

        <!-- TABELA -->
        <Datatable
          :colsUpper="datatable.gridColumnsUpper"
          :rows="datatable.data"
          :numberElements="datatable.numberElements"
          :numberPages="datatable.numberPages"
          :page="datatable.page"
          :hasSearch="true"
          title="Situações de pareceres cadastradas"
          termResults="resultados encontrados"
          class="datatable"
          @changePagination="paginationCallback"
          @changeOrdering="orderCallback"
          @search="searchCallback"
        >
          <!-- COLUNA DE AÇÕES -->
          <template v-slot:action="slotProps">
            <div class="datatable--actions">
              <!-- EDITAR PESQUISADOR -->
              <font-awesome-icon
                class="datatable--icon"
                icon="fa-solid fa-pen-to-square"
                @click="edit(slotProps.row.id)"
              />

              <!-- REMOVER PESQUISADOR -->
              <font-awesome-icon
                class="datatable--icon datatable--icon__remove"
                icon="fa-solid fa-trash-can"
                @click="dialogSituation(slotProps.row.id)"
              />
            </div>
          </template>
        </Datatable>

        <!-- EDITAR SITUAÇÃO DE PARECER -->
        <Modal
          v-model="situation.edit"
          title="EDITAR SITUAÇÃO DE PARECER"
          class="modal-edit__edit"
        >
          <!-- NOME DO SITUAÇÃO DE PARECER -->
          <FormInput
            v-model="situation.nome"
            :border="true"
            placeholder="Nome do Situação de parecer"
            class="modal-edit--input"
          />

          <div class="modal-edit--row">
            <!-- BOTÃO DE SALVAR -->
            <Button
              :radius="false"
              class="modal-edit--button"
              @click="saveType()"
            >
              GRAVAR
            </Button>
          </div>
        </Modal>

        <!-- MODAL DE CONFIRMAÇÃO DE REMOÇÃO DE PESQUISADOR -->
        <Modal
          v-model="situation.delete"
          title="REMOVER PESQUISADOR"
          class="modal-edit__remove"
        >
          <Paragraph size="60" weight="normal">
            Tem certeza que deseja remover o situação de parecer
            <strong>{{ situation.nome }}?</strong>
          </Paragraph>

          <div class="modal-edit--row">
            <!-- BOTÃO DE CANCELAR -->
            <Button
              :radius="false"
              color="outline"
              class="modal-edit--button"
              @click="closeModalType()"
            >
              CANCELAR
            </Button>

            <!-- BOTÃO DE DELETAR -->
            <Button
              :radius="false"
              color="danger"
              class="modal-edit--button"
              @click="deleteType()"
            >
              DELETAR
            </Button>
          </div>
        </Modal>
      </template>
    </AdminLayout>
  </div>
</template>

<script>
import AdminLayout from '@/layouts/AdminLayout'
import Heading from '@/components/Heading'
import Paragraph from '@/components/Paragraph'
import FormInput from '@/components/FormInput'
import Button from '@/components/Button'
import Datatable from '@/components/Datatable'
import Modal from '@/components/Modal'

export default {
  name: 'SituationOfOpinion',

  components: {
    AdminLayout,
    Heading,
    Paragraph,
    FormInput,
    Button,
    Datatable,
    Modal
  },

  data() {
    return {
      /** Nome da situação de parecer */
      nome: null,

      /** Tabela */
      datatable: {
        gridColumnsUpper: [
          { name: 'id', alias: 'ID' },
          { name: 'nome', alias: 'Nome da situação de parecer', order: true },
          { name: 'action', alias: 'Ação' }
        ],
        search: null,
        data: [],
        numberElements: 0,
        numberPages: 0,
        page: 1,
        limitPerPage: 20,
        order: null,
        direction: null
      },

      /** Situação de parecer a ser editado */
      situation: {
        id: null /** ID da situação de parecer */,
        nome: null /** Nome da situação de parecer */,
        edit: false /** Visibilidade da modal de edição */,
        delete: false /** Visibilidade da modal de remoção */
      }
    }
  },

  created() {
    /** Busca as situações de pareceres */
    this.getSituations()
  },

  methods: {
    /** Buscar as situações de pareceres */
    getSituations() {
      this.$situationOfOpinion
        .get({
          nome: this.datatable.search,
          direcao: this.datatable.direction,
          tamanhoPagina: this.datatable.limitPerPage,
          ordenarPor: this.datatable.order,
          pagina: this.datatable.page
        })
        .then((response) => {
          const situations = response.data.content.map((situation) => {
            return {
              id: situation.id,
              nome: situation.nome
            }
          })

          this.datatable.data = situations
          this.datatable.numberElements = response.data.totalElements
          this.datatable.numberPages = response.data.totalPages
        })
        .catch(() => {
          this.$toast.warning(
            'Houve um erro ao apresentar as situações de pareceres.'
          )
        })
    },

    /** Criar um situação de parecer */
    createSituation() {
      this.$situationOfOpinion
        .create({ nome: this.nome })
        .then(() => {
          /** Feedback de sucesso */
          this.$toast.success('Situação de parecer criado com sucesso!')
        })
        .catch(() => {
          /** Feedback de erro */
          this.$toast.error('Houve um erro ao criar a situação de parecer!')
        })
        .finally(() => {
          /** Limpar o campo do nome */
          this.nome = null

          /** Recarregar a lista */
          this.getSituations()
        })
    },

    /**
     * Paginação
     * @param {Number} page: Número da página selecionada
     */
    paginationCallback(page) {
      this.datatable.page = page
      this.getSituations()
    },

    /**
     * Ordenação
     * @param {Object} data: Objeto de dados da ordenação
     */
    orderCallback(data) {
      this.datatable.order = data.order
      this.datatable.direction = data.direction
      this.getSituations()
    },

    /**
     * Pesquisa da tabela
     * @param {Object} data: Objeto de dados da ordenação
     */
    searchCallback(search) {
      this.datatable.page = 1
      this.datatable.search = search
      this.getSituations()
    },

    /**
     * Exibir a modal de edição de situação de parecer
     * @param {Number} id: Id da situação de parecer
     */
    edit(id) {
      /** Localizar o situação de parecer na listagem */
      const situation = this.datatable.data.find((item) => item.id === id)

      /** Pegar os dados da situação de parecer e abrir a modal */
      if (situation) {
        this.situation.edit = true
        this.situation.id = situation.id
        this.situation.nome = situation.nome
      } else {
        this.$toast.warning('Houve um erro ao encontrar o situação de parecer.')
        this.closeModalType()
      }
    },

    /**
     * Confirmar se deseja remover o situação de parecer
     * @param {Number} id: Id da situação de parecer
     */
    dialogSituation(id) {
      /** Localizar o situação de parecer na listagem */
      const situation = this.datatable.data.find((item) => item.id === id)

      /** Pegar os dados da situação de parecer e abrir a modal de confirmação */
      if (situation) {
        this.situation.delete = true
        this.situation.id = situation.id
        this.situation.nome = situation.nome
      } else {
        this.$toast.warning('Houve um erro ao encontrar o situação de parecer.')
        this.closeModalType()
      }
    },

    /** Fechar a modal da situação de parecer */
    closeModalType() {
      this.situation.edit = false
      this.situation.delete = false
      this.situation.nome = null
    },

    /** Atualizar o situação de parecer */
    saveType() {
      this.$situationOfOpinion
        .update(this.situation.id, { nome: this.situation.nome })
        .then(() => {
          this.$toast.success('Situação de parecer atualizado com sucesso!')
        })
        .catch(() => {
          this.$toast.warning(
            'Houve um erro ao atualizar o situação de parecer.'
          )
        })
        .finally(() => {
          /** Fechar a modal */
          this.closeModalType()

          /** Recarregar a listagem */
          this.getSituations()
        })
    },

    /** Remover o situação de parecer */
    deleteType() {
      this.$situationOfOpinion
        .delete(this.situation.id)
        .then(() => {
          this.$toast.success('Situação de parecer removido com sucesso!')
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao remover o situação de parecer.')
        })
        .finally(() => {
          /** Fechar a modal */
          this.closeModalType()

          /** Recarregar a listagem */
          this.getSituations()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
}
.form {
  display: flex;
  align-items: flex-end;
  background: $gray;
  padding: 7px;
  border-radius: 5px;
  gap: 7px;
  margin-bottom: 25px;

  &--input {
    margin: 0px;
  }

  &--button {
    margin: 0px;
  }
}

.datatable {
  &--link {
    cursor: pointer;
    text-decoration: underline;
  }

  ::v-deep {
    thead th:nth-child(1),
    tr td:nth-child(1) {
      width: 50px;
      text-align: center;
    }

    thead th:nth-child(3),
    tr td:nth-child(3) {
      width: 120px;
    }
  }

  &--actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  &--icon {
    color: $primary;
    text-align: center;
    cursor: pointer;

    &__remove {
      color: $danger;
    }
  }
}

/** MODAL DE EDIÇÃO DO SITUAÇÃO DE PARECER */
.modal-edit {
  ::v-deep {
    .paragraph {
      line-height: 20px;
    }
  }

  &--row {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;
  }

  &--button {
    display: block;
  }

  &__edit {
    ::v-deep {
      .modal-component--content {
        max-width: 600px;
      }
    }
  }

  &__remove {
    ::v-deep {
      .modal-component--content {
        max-width: 500px;
      }
    }
  }
}
</style>
