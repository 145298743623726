export default ($axios) => ({
  /** Retorna a listagem de usuários */
  get: () => $axios.get('/user'),

  /**
   * Cria um usuário
   * @param {Object} data
   */
  create: (data) =>
    $axios.post('/user', {
      ...data
    }),

  /**
   * Atualizar um usuário
   * @param { Object } data: "firstName", "lastName", "email", "password" para editar um usuário
   */
  update: (data) =>
    $axios.put('/user', {
      ...data
    }),

  /**
   * Busca todos os usuários
   * @param { Object } data: "direction" "limitPerPage" "name" "orderBy" e "page" para a consulta
   */
  getAll: (data) =>
    $axios.get('/user/all', {
      params: {
        ...data
      }
    }),

  /**
   * Remove um usuário
   * @param {Number} id: Id do usuário
   */
  delete: (id = '') => $axios.delete(`/user/${id}`)
})
