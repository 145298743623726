export default ($axios) => ({
  /**
   * Cria uma apreciação
   * @param { Object } data: "nome" para criar uma apreciação
   */
  create: (data) =>
    $axios.post('/apreciacao', {
      ...data
    }),

  /**
   * Atualizar uma apreciação
   * @param { Number } id: "id" do apreciacao
   * @param { Object } data: "nome" para editar uma apreciação
   */
  update: (id, data) =>
    $axios.put(`/apreciacao/${id}`, {
      ...data
    }),

  /**
   * Busca os apreciacao
   * @param { Object } data: "id" "direcao" "tamanhoPagina" "nome" "ordenarPor" e "pagina" para a consulta
   */
  get: (data) =>
    $axios.get(`/apreciacao/${data?.id ?? ''}`, {
      params: {
        ...data
      }
    }),

  /** Remove uma apreciação */
  delete: (id = '') => $axios.delete(`/apreciacao/${id}`)
})
