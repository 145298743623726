<template>
  <div class="form-select">
    <div class="form-select--body">
      <!-- LABEL -->
      <label v-if="showLabel" :for="`select-${id}`" class="form-select--label">
        <Paragraph size="40" weight="medium">{{ label }}</Paragraph>
      </label>

      <!-- CAMPO DE SELECT -->
      <v-select
        :multiple="multiple"
        :value="value"
        :options="options"
        :placeholder="placeholder"
        :searchable="true"
        :class="{
          'form-select--field__radius': radius,
          'form-select--field__placeholder': !value
        }"
        class="form-select--field__vselect"
        @input="$emit('input', $event)"
        @search="$emit('search', $event)"
      >
        <template slot="list-footer">
          <button
            v-if="hasNext"
            class="form-select--load-more"
            @click="$emit('load')"
          >
            Carregar mais
          </button>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import Paragraph from '@/components/Paragraph'

/** Componente padrão de select */
export default {
  name: 'FormSelect',

  components: {
    Paragraph
  },

  props: {
    /** Mostrar o label do campo */
    showLabel: {
      type: Boolean,
      default: false
    },

    /** Label do campo */
    label: {
      type: String,
      default: 'label'
    },

    /** Placeholder do campo */
    placeholder: {
      type: String,
      default: null
    },

    /** Nome do campo */
    name: {
      type: String,
      default: ''
    },

    /** Valor do campo */
    value: {
      type: [String, Number, Object, Array],
      default: ''
    },

    /** Opções do campo */
    options: {
      type: [String, Number, Object, Array],
      default: ''
    },

    /** Maior arredondamento do campo de input */
    radius: {
      type: Boolean,
      default: false
    },

    /** Se o input deve ter borda */
    border: {
      type: Boolean,
      default: false
    },

    /** Se deve ser múltiplo */
    multiple: {
      type: Boolean,
      default: false
    },

    /** Se deve exibir o botão de carregar mais dados */
    hasNext: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      id: this._uid /** Id único do componente */
    }
  },

  methods: {
    /**
     * Observar alterações do(s) item(ns) selecinado(s)
     * @param {Event} event
     */
    onChange(event) {
      this.$emit('input', event.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.form-select {
  position: relative;
  width: 100%;
  margin-bottom: 15px;
  cursor: pointer;

  &--field {
    background: $white;
    padding: 7px 20px;
    color: $fontColor;
    width: 100%;
    border: 0;
    font-size: paragraph-size('paragraph-60');
    line-height: paragraph-size('paragraph-60');
    font-weight: font-weight('weight-normal');
    border-radius: 5px;

    &:focus {
      outline: none;
    }

    &__radius {
      border-radius: 50px;
    }

    &__border {
      border: 1px solid $gray;
    }

    &__vselect {
      ::v-deep {
        .vs__dropdown-toggle {
          border: 1px solid $gray;
          border-radius: 5px;
          cursor: pointer;
          padding: 0px;
        }
        .vs__open-indicator {
          fill: $primary;
          transform: scale(0.7);
        }
        .vs__search {
          cursor: pointer;
          margin: 0;
          padding: 0px;
          font-size: paragraph-size('paragraph-60');
          line-height: paragraph-size('paragraph-60');
          filter: grayscale(100%);
          color: $fontColor;
          opacity: 0.7;
        }
        .vs__actions {
          padding: 0px;
          margin-right: 5px;
        }
        .vs__selected {
          margin: 0px;
          font-size: paragraph-size('paragraph-60');
          line-height: paragraph-size('paragraph-60');

          &-options {
            padding: 6px 20px;
            line-height: paragraph-size('paragraph-60');
          }
        }
        .vs__deselect {
          transform: scale(0.7);
        }
        .vs__dropdown-option {
          font-size: paragraph-size('paragraph-60');
          line-height: paragraph-size('paragraph-60');

          &--highlight,
          &--selected {
            background: $primary;
            color: $white;
          }
        }
      }
    }
  }

  &--load-more {
    border: 0;
    background: unset;
    display: block;
    margin: 0px auto;
    color: $primary;
    cursor: pointer;
    margin-top: 10px;
    font-size: paragraph-size('paragraph-40');
  }

  &--label {
    margin-bottom: 5px;

    .paragraph {
      margin-bottom: 0px;
    }
  }
}
</style>
