<template>
  <div class="login">
    <LoginLayout>
      <template #content>
        <div class="login--container">
          <form @submit="login">
            <!-- CAMPO DE E-MAIL -->
            <FormInput
              v-model="email"
              :border="true"
              label="Usuário"
              type="email"
              placeholder="Insira o e-mail"
              class="login--input"
            />
            <!-- CAMPO DE SENHA -->
            <FormInput
              v-model="password"
              :border="true"
              label="Senha"
              type="password"
              placeholder="Insira a senha"
              class="login--input"
            />
            <Paragraph v-if="message" size="40" class="login--message">
              {{ message }}
            </Paragraph>
            <!-- BOTÃO DE LOGIN -->
            <Button
              :disabled="loading"
              :radius="false"
              class="login--button"
              type="submit"
            >
              ENTRAR
            </Button>
          </form>
        </div>
      </template>
    </LoginLayout>
  </div>
</template>

<script>
import { setCurrentUserAccess } from '@/helpers/auth'
import LoginLayout from '@/layouts/LoginLayout'
import Paragraph from '@/components/Paragraph'
import FormInput from '@/components/FormInput'
import Button from '@/components/Button'

export default {
  name: 'Home',
  components: {
    LoginLayout,
    Paragraph,
    FormInput,
    Button
  },
  data() {
    return {
      email: null /** E-mail */,
      password: null /** Senha */,
      message: null /** Mensagem de erro */,
      loading: false /** Se está carregando */
    }
  },
  methods: {
    /** Login
     * @param {Event} e: Capturar evento do formulário de login
     */
    login(e) {
      /** Carregando loading */
      this.loading = true
      e.preventDefault()

      this.$auth
        .login({ email: this.email, password: this.password })
        .then((response) => {
          this.hideError()
          if (response.headers.authorization) {
            this.setAuthenticate(response.headers.authorization)
          } else {
            this.showError()
          }
        })
        .catch(() => {
          this.showError()
          this.$toast.warning('A senha inserida está incorreta.')
        })
        .finally(() => {
          /** Remover o loading */
          this.loading = false
        })
    },
    /** Autenticar usuário e redirecionar para a Tela inicial
     * @param {String} token - Token de seis dígitos
     */
    setAuthenticate(token) {
      this.message = null
      /** Guardar token de acesso */
      setCurrentUserAccess(token)
      /** Redirecionar */
      window.location = '/'
    },
    /** Exibir os erros */
    showError() {
      this.message = 'O e-mail ou a senha estão incorretos'
      this.password = null
    },
    /** Esconde os erros */
    hideError() {
      this.message = null
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  &--button {
    display: block;
    margin-left: auto;
    width: 100%;
  }

  &--message {
    color: $error;
    text-align: center;
  }
}
</style>
