export default {
  namespaced: true,

  state: () => ({
    id: null,
    primeiroNome: null,
    ultimoNome: null,
    email: null,
    perfis: null
  }),

  mutations: {
    updateUserData(state, data) {
      for (const value in data) {
        state[value] = data[value]
      }
    }
  },

  actions: {
    requestUserData({ commit }) {
      this._vm.$auth.getUserData().then((response) => {
        commit('updateUserData', response.data)
      })
    }
  },
  getters: {
    getId: (state) => state.id,
    getName: (state) => state.primeiroNome,
    getFirstName: (state) => state.primeiroNome,
    getLastName: (state) => state.ultimoNome,
    getEmail: (state) => state.email,
    getRole: (state) => state.perfis
  }
}
