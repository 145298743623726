import axios from 'axios'

export default ($axios) => ({
  /**
   * Cria um cep
   * @param { Object } data: "nome", "ativo", "regiaoId", "uf", "municipio", "telefone", "email" para criar um cep
   */
  create: (data) =>
    $axios.post('/cep', {
      ...data
    }),

  /**
   * Cria um cep a partir de um arquivo CSV
   * @param {Object} data
   */
  upload: (data) =>
    $axios.post('/cep/csv', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }),

  /**
   * Atualizar um cep
   * @param { Number } id: "id" do cep
   * @param { Object } data: "apreciacaoId", "caae", "pesquisadorId", "membroCepId", "tipoParecerId", "situacaoParecerId", "dataSubmissao", "dataParecer" para editar um cep
   */
  update: (id, data) =>
    $axios.put(`/cep/${id}`, {
      ...data
    }),

  /**
   * Busca os cep
   * @param { Object } data: "termo" "direcao" "tamanhoPagina" "ordenarPor" e "pagina" para a consulta
   */
  get: (data) =>
    $axios.get(data.termo ? '/cep/search' : '/cep', {
      params: {
        ...data
      }
    }),

  /** Remove um cep */
  delete: (id = '') => $axios.delete(`/cep/${id}`),

  /** Pegar os UFs */
  getUfs: () =>
    axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
})
