<template>
  <div class="home">
    <AdminLayout page="Usuários">
      <template #content>
        <!-- TÍTULO -->
        <Heading size="20" weight="bold" class="title">Cadastrar</Heading>
        <!-- FORMULÁRIO DE CADASTRO DE USUÁRIOS -->
        <div class="form">
          <!-- CAMPO DO NOME -->
          <FormInput
            v-model="primeiroNome"
            label="Nome"
            placeholder="Insira o Nome"
            class="form--input"
          />

          <!-- CAMPO DO SOBRENOME -->
          <FormInput
            v-model="ultimoNome"
            label="Sobrenome"
            placeholder="Insira o Sobrenome"
            class="form--input"
          />

          <!-- CAMPO DO E-MAIL -->
          <FormInput
            v-model="email"
            type="email"
            label="E-mail"
            placeholder="Insira o E-mail"
            class="form--input"
          />

          <!-- CAMPO DA SENHA -->
          <FormInput
            v-model="senha"
            type="password"
            label="Senha"
            placeholder="Insira a Senha"
            class="form--input"
          />

          <!-- CAMPO DO TIPO -->
          <FormSelect
            v-model="perfil"
            :options="perfilOptions"
            :showLabel="true"
            label="Cargo"
            placeholder="Selecione o Cargo"
            class="form--input"
          />

          <!-- BOTÃO DE ENVIAR -->
          <Button :radius="false" class="form--button" @click="createUser()">
            CADASTRAR
          </Button>
        </div>

        <!-- TABELA -->
        <Datatable
          :colsUpper="datatable.gridColumnsUpper"
          :rows="datatable.data"
          :numberElements="datatable.numberElements"
          :numberPages="datatable.numberPages"
          :page="datatable.page"
          :hasSearch="true"
          title="Usuários cadastrados"
          termResults="resultados encontrados"
          class="datatable"
          @changePagination="paginationCallback"
          @changeOrdering="orderCallback"
          @search="searchCallback"
        >
          <!-- COLUNA DE AÇÕES -->
          <template v-slot:action="slotProps">
            <div class="datatable--actions">
              <font-awesome-icon
                class="datatable--icon datatable--icon__remove"
                icon="fa-solid fa-trash-can"
                @click="dialogUser(slotProps.row.id)"
              />
            </div>
          </template>
        </Datatable>

        <!-- MODAL DE CONFIRMAÇÃO DE REMOÇÃO DE USUÁRIO -->
        <Modal
          v-model="user.delete"
          title="REMOVER USUÁRIO"
          class="modal-edit__remove"
        >
          <Paragraph size="60" weight="normal">
            Tem certeza que deseja remover o usuário
            <strong>{{ user.primeiroNome }}?</strong>
          </Paragraph>
          <div class="modal-edit--row">
            <!-- BOTÃO DE CANCELAR -->
            <Button
              :radius="false"
              color="outline"
              class="modal-edit--button"
              @click="closeModalUser()"
            >
              CANCELAR
            </Button>
            <!-- BOTÃO DE DELETAR -->
            <Button
              :radius="false"
              color="danger"
              class="modal-edit--button"
              @click="deleteUser()"
            >
              DELETAR
            </Button>
          </div>
        </Modal>
      </template>
    </AdminLayout>
  </div>
</template>

<script>
import AdminLayout from '@/layouts/AdminLayout'
import Heading from '@/components/Heading'
import Paragraph from '@/components/Paragraph'
import FormInput from '@/components/FormInput'
import FormSelect from '@/components/FormSelect'
import Button from '@/components/Button'
import Datatable from '@/components/Datatable'
import Modal from '@/components/Modal'

import { mapGetters } from 'vuex'

export default {
  name: 'Users',

  components: {
    AdminLayout,
    Heading,
    Paragraph,
    FormInput,
    FormSelect,
    Button,
    Datatable,
    Modal
  },

  data() {
    return {
      /** Nome do usuário */
      primeiroNome: null,
      /** Sobrenome do usuário */
      ultimoNome: null,
      /** E-mail do usuário */
      email: null,
      /** Senha do usuário */
      senha: null,
      /** Cargo do usuário */
      perfil: null,

      /** Lista de cargos para o select */
      perfilOptions: [
        {
          id: 'tecnico',
          label: 'Administrador'
        },
        {
          id: 'gestor',
          label: 'Gestor'
        },
        {
          id: 'tecnico',
          label: 'Técnico'
        }
      ],

      /** Tabela */
      datatable: {
        gridColumnsUpper: [
          { name: 'id', alias: 'ID' },
          { name: 'primeiroNome', alias: 'Usuário', order: true },
          { name: 'email', alias: 'E-mail' },
          { name: 'perfil', alias: 'Cargo' },
          { name: 'action', alias: 'Ação' }
        ],
        search: null,
        data: [],
        numberElements: 0,
        numberPages: 0,
        page: 1,
        limitPerPage: 20,
        order: null,
        direction: null
      },

      /** Usuário a ser editado */
      user: {
        id: null /** ID do usuário */,
        primeiroNome: null /** Nome do usuário */,
        delete: false /** Visibilidade da modal de remoção */
      }
    }
  },

  computed: {
    ...mapGetters('user', ['getId'])
  },

  created() {
    /** Busca os usuários */
    this.getUsers()
  },

  methods: {
    /** Buscar os usuários */
    getUsers() {
      this.$user
        .getAll({
          nome: this.datatable.search,
          direcao: this.datatable.direction,
          tamanhoPagina: this.datatable.limitPerPage,
          ordenarPor: this.datatable.order,
          pagina: this.datatable.page
        })
        .then((response) => {
          const users = response.data.content.map((user) => {
            return {
              id: user.id,
              primeiroNome: `${user.primeiroNome} ${user.ultimoNome}`,
              dataprimeiroNome: user.primeiroNome,
              dataultimoNome: user.ultimoNome,
              email: user.email,
              perfil: user?.perfis.toString().toLowerCase()
            }
          })
          this.datatable.data = users
          this.datatable.numberElements = response.data.totalElements
          this.datatable.numberPages = response.data.totalPages
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao apresentar os usuários.')
        })
    },

    /** Criar um usuário */
    createUser() {
      this.$user
        .create({
          primeiroNome: this.primeiroNome,
          ultimoNome: this.ultimoNome,
          email: this.email,
          senha: this.senha,
          perfil: this.perfil?.id
        })
        .then(() => {
          /** Feedback de sucesso */
          this.$toast.success('Usuário criado com sucesso!')
        })
        .catch(() => {
          /** Feedback de erro */
          this.$toast.error('Houve um erro ao criar o Usuário!')
        })
        .finally(() => {
          /** Limpar o campo do nome */
          this.primeiroNome = null
          this.ultimoNome = null
          this.email = null
          this.senha = null
          this.perfil = null
          /** Recarregar a lista */
          this.getUsers()
        })
    },

    /**
     * Paginação
     * @param {Number} page: Número da página selecionada
     */
    paginationCallback(page) {
      this.datatable.page = page
      this.getUsers()
    },

    /**
     * Ordenação
     * @param {Object} data: Objeto de dados da ordenação
     */
    orderCallback(data) {
      this.datatable.order = data.order
      this.datatable.direction = data.direction
      this.getUsers()
    },

    /**
     * Pesquisa da tabela
     * @param {Object} data: Objeto de dados da ordenação
     */
    searchCallback(search) {
      this.datatable.page = 1
      this.datatable.search = search
      this.getUsers()
    },

    /**
     * Confirmar se deseja remover o usuário
     * @param {Number} id: Id do usuário
     */
    dialogUser(id) {
      /** Localizar o usuário na listagem */
      const user = this.datatable.data.find((item) => item.id === id)
      /** Pegar os dados do usuário e abrir a modal de confirmação */
      if (user) {
        this.user.delete = true
        this.user.id = user.id
        this.user.primeiroNome = user.primeiroNome
      } else {
        this.$toast.warning('Houve um erro ao encontrar o usuário.')
        this.closeModalUser()
      }
    },

    /** Fechar a modal de edição de usuário */
    closeModalUser() {
      this.user.delete = false
      this.user.primeiroNome = null
    },

    /** Remover o usuário */
    deleteUser() {
      this.$user
        .delete(this.user.id)
        .then(() => {
          this.$toast.success('Usuário removido com sucesso!')
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao remover o usuário.')
        })
        .finally(() => {
          /** Fechar a modal */
          this.closeModalUser()
          /** Recarregar a listagem */
          this.getUsers()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
}
.form {
  display: flex;
  align-items: flex-end;
  background: $gray;
  padding: 7px;
  border-radius: 5px;
  gap: 7px;
  margin-bottom: 25px;

  &--input {
    margin: 0px;
  }

  &--button {
    margin: 0px;
  }
}

.datatable {
  &--link {
    cursor: pointer;
    text-decoration: underline;
  }

  ::v-deep {
    thead th:nth-child(1),
    tr td:nth-child(1) {
      width: 50px;
      text-align: center;
    }

    thead th:nth-child(3),
    tr td:nth-child(3) {
      width: 350px;
    }

    thead th:nth-child(4),
    tr td:nth-child(4) {
      width: 150px;

      .paragraph {
        text-transform: capitalize;
      }
    }

    thead th:nth-child(5),
    tr td:nth-child(5) {
      width: 120px;
    }
  }

  &--actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  &--icon {
    color: $primary;
    text-align: center;
    cursor: pointer;

    &__remove {
      color: $danger;
    }
  }
}

/** MODAL DE EDIÇÃO DO USUÁRIO */
.modal-edit {
  ::v-deep {
    .paragraph {
      line-height: 20px;
    }
  }

  &--row {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;
  }

  &--button {
    display: block;
  }

  &__edit {
    ::v-deep {
      .modal-component--content {
        max-width: 600px;
      }
    }
  }

  &__remove {
    ::v-deep {
      .modal-component--content {
        max-width: 500px;
      }
    }
  }
}
</style>
