<template>
  <div class="home">
    <AdminLayout page="Pesquisadores">
      <template #content>
        <!-- TÍTULO -->
        <Heading size="20" weight="bold" class="title">Cadastrar</Heading>

        <!-- FORMULÁRIO DE CADASTRO DE PESQUISADORES -->
        <div class="form">
          <!-- CAMPO DO NOME -->
          <FormInput v-model="nome" placeholder="Nome" class="form--input" />

          <!-- BOTÃO DE ENVIAR -->
          <Button
            :radius="false"
            class="form--button"
            @click="createResearcher()"
          >
            CADASTRAR
          </Button>
        </div>

        <!-- TABELA -->
        <Datatable
          :colsUpper="datatable.gridColumnsUpper"
          :rows="datatable.data"
          :numberElements="datatable.numberElements"
          :numberPages="datatable.numberPages"
          :page="datatable.page"
          :hasSearch="true"
          title="Pesquisadores cadastrados"
          termResults="resultados encontrados"
          class="datatable"
          @changePagination="paginationCallback"
          @changeOrdering="orderCallback"
          @search="searchCallback"
        >
          <!-- COLUNA DE AÇÕES -->
          <template v-slot:action="slotProps">
            <div class="datatable--actions">
              <!-- EDITAR PESQUISADOR -->
              <font-awesome-icon
                class="datatable--icon"
                icon="fa-solid fa-pen-to-square"
                @click="edit(slotProps.row.id)"
              />

              <!-- REMOVER PESQUISADOR -->
              <font-awesome-icon
                class="datatable--icon datatable--icon__remove"
                icon="fa-solid fa-trash-can"
                @click="dialogResearcher(slotProps.row.id)"
              />
            </div>
          </template>
        </Datatable>

        <!-- EDITAR PESQUISADOR -->
        <Modal
          v-model="researcher.edit"
          title="EDITAR PESQUISADOR"
          class="modal-edit__edit"
        >
          <!-- NOME DO PESQUISADOR -->
          <FormInput
            v-model="researcher.nome"
            :border="true"
            label="Nome do Pesquisador"
            placeholder="Insira o nome"
            class="modal-edit--input"
          />

          <div class="modal-edit--row">
            <!-- BOTÃO DE SALVAR -->
            <Button
              :radius="false"
              class="modal-edit--button"
              @click="saveResearcher()"
            >
              GRAVAR
            </Button>
          </div>
        </Modal>

        <!-- MODAL DE CONFIRMAÇÃO DE REMOÇÃO DE PESQUISADOR -->
        <Modal
          v-model="researcher.delete"
          title="REMOVER PESQUISADOR"
          class="modal-edit__remove"
        >
          <Paragraph size="60" weight="normal">
            Tem certeza que deseja remover o pesquisador
            <strong>{{ researcher.nome }}?</strong>
          </Paragraph>

          <div class="modal-edit--row">
            <!-- BOTÃO DE CANCELAR -->
            <Button
              :radius="false"
              color="outline"
              class="modal-edit--button"
              @click="closeModalResearcher()"
            >
              CANCELAR
            </Button>

            <!-- BOTÃO DE DELETAR -->
            <Button
              :radius="false"
              color="danger"
              class="modal-edit--button"
              @click="deleteResearcher()"
            >
              DELETAR
            </Button>
          </div>
        </Modal>
      </template>
    </AdminLayout>
  </div>
</template>

<script>
import AdminLayout from '@/layouts/AdminLayout'
import Heading from '@/components/Heading'
import Paragraph from '@/components/Paragraph'
import FormInput from '@/components/FormInput'
import Button from '@/components/Button'
import Datatable from '@/components/Datatable'
import Modal from '@/components/Modal'

export default {
  name: 'Researcher',

  components: {
    AdminLayout,
    Heading,
    Paragraph,
    FormInput,
    Button,
    Datatable,
    Modal
  },

  data() {
    return {
      /** Nome do pesquisador */
      nome: null,

      /** Tabela */
      datatable: {
        gridColumnsUpper: [
          { name: 'id', alias: 'ID' },
          { name: 'nome', alias: 'Nome do pesquisador', order: true },
          { name: 'action', alias: 'Ação' }
        ],
        search: null,
        data: [],
        numberElements: 0,
        numberPages: 0,
        page: 1,
        limitPerPage: 20,
        order: null,
        direction: null
      },

      /** Pesquisador a ser editado */
      researcher: {
        id: null /** ID do pesquisador */,
        nome: null /** Nome do pesquisador */,
        edit: false /** Visibilidade da modal de edição */,
        delete: false /** Visibilidade da modal de remoção */
      }
    }
  },

  created() {
    /** Busca os pesquisadores */
    this.getResearchers()
  },

  methods: {
    /** Buscar os pesquisadors */
    getResearchers() {
      this.$researcher
        .get({
          nome: this.datatable.search,
          direcao: this.datatable.direction,
          tamanhoPagina: this.datatable.limitPerPage,
          ordenarPor: this.datatable.order,
          pagina: this.datatable.page
        })
        .then((response) => {
          const researchers = response.data.content.map((researcher) => {
            return {
              id: researcher.id,
              nome: researcher.nome
            }
          })
          this.datatable.data = researchers
          this.datatable.numberElements = response.data.totalElements
          this.datatable.numberPages = response.data.totalPages
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao apresentar os pesquisadors.')
        })
    },

    /** Criar um pesquisador */
    createResearcher() {
      this.$researcher
        .create({ nome: this.nome })
        .then(() => {
          /** Feedback de sucesso */
          this.$toast.success('Pesquisador criado com sucesso!')
        })
        .catch(() => {
          /** Feedback de erro */
          this.$toast.error('Houve um erro ao criar o Pesquisador!')
        })
        .finally(() => {
          /** Limpar o campo do nome */
          this.nome = null

          /** Recarregar a lista */
          this.getResearchers()
        })
    },

    /**
     * Paginação
     * @param {Number} page: Número da página selecionada
     */
    paginationCallback(page) {
      this.datatable.page = page
      this.getResearchers()
    },

    /**
     * Ordenação
     * @param {Object} data: Objeto de dados da ordenação
     */
    orderCallback(data) {
      this.datatable.order = data.order
      this.datatable.direction = data.direction
      this.getResearchers()
    },

    /**
     * Pesquisa da tabela
     * @param {Object} data: Objeto de dados da ordenação
     */
    searchCallback(search) {
      this.datatable.page = 1
      this.datatable.search = search
      this.getResearchers()
    },

    /**
     * Exibir a modal de edição de pesquisador
     * @param {Number} id: Id do pesquisador
     */
    edit(id) {
      /** Localizar o pesquisador na listagem */
      const researcher = this.datatable.data.find((item) => item.id === id)

      /** Pegar os dados do pesquisador e abrir a modal */
      if (researcher) {
        this.researcher.edit = true
        this.researcher.id = researcher.id
        this.researcher.nome = researcher.nome
      } else {
        this.$toast.warning('Houve um erro ao encontrar o pesquisador.')
        this.closeModalResearcher()
      }
    },

    /**
     * Confirmar se deseja remover o pesquisador
     * @param {Number} id: Id do pesquisador
     */
    dialogResearcher(id) {
      /** Localizar o pesquisador na listagem */
      const researcher = this.datatable.data.find((item) => item.id === id)

      /** Pegar os dados do pesquisador e abrir a modal de confirmação */
      if (researcher) {
        this.researcher.delete = true
        this.researcher.id = researcher.id
        this.researcher.nome = researcher.nome
      } else {
        this.$toast.warning('Houve um erro ao encontrar o pesquisador.')
        this.closeModalResearcher()
      }
    },

    /** Fechar a modal do pesquisador */
    closeModalResearcher() {
      this.researcher.edit = false
      this.researcher.delete = false
      this.researcher.nome = null
    },

    /** Atualizar o pesquisador */
    saveResearcher() {
      this.$researcher
        .update(this.researcher.id, { nome: this.researcher.nome })
        .then(() => {
          this.$toast.success('Pesquisador atualizado com sucesso!')
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao atualizar o pesquisador.')
        })
        .finally(() => {
          /** Fechar a modal */
          this.closeModalResearcher()

          /** Recarregar a listagem */
          this.getResearchers()
        })
    },

    /** Remover o pesquisador */
    deleteResearcher() {
      this.$researcher
        .delete(this.researcher.id)
        .then(() => {
          this.$toast.success('Pesquisador removido com sucesso!')
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao remover o pesquisador.')
        })
        .finally(() => {
          /** Fechar a modal */
          this.closeModalResearcher()
          /** Recarregar a listagem */
          this.getResearchers()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
}
.form {
  display: flex;
  align-items: flex-end;
  background: $gray;
  padding: 7px;
  border-radius: 5px;
  gap: 7px;
  margin-bottom: 25px;

  &--input {
    margin: 0px;
  }

  &--button {
    margin: 0px;
  }
}

.datatable {
  &--link {
    cursor: pointer;
    text-decoration: underline;
  }

  ::v-deep {
    thead th:nth-child(1),
    tr td:nth-child(1) {
      width: 70px;
      text-align: center;
    }

    thead th:nth-child(3),
    tr td:nth-child(3) {
      width: 120px;
    }
  }

  &--actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  &--icon {
    color: $primary;
    text-align: center;
    cursor: pointer;

    &__remove {
      color: $danger;
    }
  }
}

/** MODAL DE EDIÇÃO DO PESQUISADOR */
.modal-edit {
  ::v-deep {
    .paragraph {
      line-height: 20px;
    }
  }

  &--row {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;
  }

  &--button {
    display: block;
  }

  &__edit {
    ::v-deep {
      .modal-component--content {
        max-width: 600px;
      }
    }
  }

  &__remove {
    ::v-deep {
      .modal-component--content {
        max-width: 500px;
      }
    }
  }
}
</style>
