<template>
  <div class="home">
    <AdminLayout page="Pareceres">
      <template #content>
        <!-- TÍTULO -->
        <Heading size="20" weight="bold" class="title">Cadastrar</Heading>

        <!-- FORMULÁRIO DE CADASTRO DE PARECERES -->
        <div class="form">
          <!-- CAMPO DO CAAE -->
          <FormInput
            v-model="caae"
            :hasMask="true"
            mask="########.#.####.####"
            label="CAAE"
            placeholder="Insira o CAAE"
            class="form--input"
          />

          <!-- APRECIAÇÃO -->
          <FormSelect
            v-model="apreciacaoId"
            :options="apreciacaoOptions"
            :hasNext="apreciacaoHasNext"
            :showLabel="true"
            label="Apreciação"
            placeholder="Selecione a Apreciação"
            class="form--input"
            @search="getAppreciations($event, true)"
            @load="getAppreciations($event, false)"
          />

          <!-- PARECER -->
          <FormSelect
            v-model="pesquisadorId"
            :options="pesquisadorOptions"
            :hasNext="pesquisadorHasNext"
            :showLabel="true"
            label="Pesquisador"
            placeholder="Selecione o Pesquisador"
            class="form--input"
            @search="getResearchers($event, true)"
            @load="getResearchers($event, false)"
          />

          <!-- MEMBRO DO CEP -->
          <FormSelect
            v-model="membroCepId"
            :options="membroCepOptions"
            :hasNext="membroCepHasNext"
            :showLabel="true"
            label="Membro do CEP"
            placeholder="Selecione o Membro do CEP"
            class="form--input"
            @search="getMembersCep($event, true)"
            @load="getMembersCep($event, false)"
          />

          <!-- TIPO DE PARECER -->
          <FormSelect
            v-model="tipoParecerId"
            :options="tipoParecerOptions"
            :hasNext="tipoParecerHasNext"
            :showLabel="true"
            label="Tipo de parecer"
            placeholder="Selecione o Tipo de parecer"
            class="form--input"
            @search="getTypes($event, true)"
            @load="getTypes($event, false)"
          />

          <!-- SITUAÇÃO DE PARECER -->
          <FormSelect
            v-model="situacaoParecerId"
            :options="situacaoParecerOptions"
            :hasNext="situacaoParecerHasNext"
            :showLabel="true"
            label="Situação de parecer"
            placeholder="Selecione a Situação de parecer"
            class="form--input"
            @search="getSituations($event, true)"
            @load="getSituations($event, false)"
          />

          <!-- DATA DE SUBMISSÃO -->
          <FormInput
            v-model="dataSubmissao"
            type="date"
            label="Data de submissão"
            class="form--input"
          />

          <!-- DATA DO PARECER -->
          <FormInput
            v-model="dataParecer"
            type="date"
            label="Data do Parecer"
            class="form--input"
          />

          <!-- BOTÃO DE ENVIAR -->
          <Button :radius="false" class="form--button" @click="createOpinion()">
            CADASTRAR
          </Button>
        </div>

        <!-- TÍTULO FORMULÁRIO EXCEL -->
        <Heading size="20" weight="bold" class="title"
          >Cadastrar por um arquivo CSV</Heading
        >

        <!-- FORMULÁRIO DE CADASTRO DE PARECERES POR EXCEL -->
        <div class="form form__csv-file">
          <!-- CAMPO DO ARQUIVO DE UPLOAD DE PARECERES -->
          <FormInputFile
            v-model="fileCSV"
            :showLabel="true"
            label="Arquivo CSV"
            class="form--input"
          />

          <!-- BOTÃO DE ENVIAR -->
          <Button :radius="false" class="form--button" @click="uploadFile()">
            ENVIAR
          </Button>
        </div>

        <!-- TABELA -->
        <Datatable
          :colsUpper="datatable.gridColumnsUpper"
          :rows="datatable.data"
          :numberElements="datatable.numberElements"
          :numberPages="datatable.numberPages"
          :page="datatable.page"
          title="Pareceres cadastrados"
          termResults="resultados encontrados"
          class="datatable"
          @changePagination="paginationCallback"
          @changeOrdering="orderCallback"
          @search="searchCallback"
        >
          <!-- COLUNA DE DATA DE PARECER -->
          <template v-slot:dataParecer="slotProps">
            <Paragraph size="60" weight="normal">{{
              slotProps.row.dataParecerFormat
            }}</Paragraph>
          </template>

          <!-- COLUNA DE DATA DE SUBMISSÃO -->
          <template v-slot:dataSubmissao="slotProps">
            <Paragraph size="60" weight="normal">{{
              slotProps.row.dataSubmissaoFormat
            }}</Paragraph>
          </template>

          <!-- COLUNA DE AÇÕES -->
          <template v-slot:action="slotProps">
            <div class="datatable--actions">
              <!-- EDITAR PARECER -->
              <font-awesome-icon
                class="datatable--icon"
                icon="fa-solid fa-pen-to-square"
                @click="edit(slotProps.row.id)"
              />

              <!-- REMOVER PARECER -->
              <font-awesome-icon
                class="datatable--icon datatable--icon__remove"
                icon="fa-solid fa-trash-can"
                @click="dialogOpinion(slotProps.row.id)"
              />
            </div>
          </template>
        </Datatable>

        <!-- EDITAR PARECER -->
        <Modal
          v-model="opinion.edit"
          title="EDITAR PARECER"
          class="modal-edit__edit"
        >
          <div class="form__edit">
            <!-- CAMPO DO CAAE -->
            <FormInput
              v-model="opinion.caae"
              :hasMask="true"
              :border="true"
              mask="########.#.####.####"
              label="CAAE"
              placeholder="Insira o CAAE"
              class="form--input"
            />

            <!-- APRECIAÇÃO -->
            <FormSelect
              v-model="opinion.apreciacao"
              :options="apreciacaoOptions"
              :hasNext="apreciacaoHasNext"
              :showLabel="true"
              label="Apreciação"
              placeholder="Selecione a Apreciação"
              class="form--input"
              @search="getAppreciations($event, true)"
              @load="getAppreciations($event, false)"
            />

            <!-- PARECER -->
            <FormSelect
              v-model="opinion.pesquisador"
              :options="pesquisadorOptions"
              :hasNext="pesquisadorHasNext"
              :showLabel="true"
              label="Pesquisador"
              placeholder="Selecione o Pesquisador"
              class="form--input"
              @search="getResearchers($event, true)"
              @load="getResearchers($event, false)"
            />

            <!-- MEMBRO DO CEP -->
            <FormSelect
              v-model="opinion.membroCep"
              :options="membroCepOptions"
              :hasNext="membroCepHasNext"
              :showLabel="true"
              label="Membro do CEP"
              placeholder="Selecione o Membro do CEP"
              class="form--input"
              @search="getMembersCep($event, true)"
              @load="getMembersCep($event, false)"
            />

            <!-- TIPO DE PARECER -->
            <FormSelect
              v-model="opinion.tipoParecer"
              :options="tipoParecerOptions"
              :hasNext="tipoParecerHasNext"
              :showLabel="true"
              label="Tipo de parecer"
              placeholder="Selecione o Tipo de parecer"
              class="form--input"
              @search="getTypes($event, true)"
              @load="getTypes($event, false)"
            />

            <!-- SITUAÇÃO DE PARECER -->
            <FormSelect
              v-model="opinion.situacaoParecer"
              :options="situacaoParecerOptions"
              :hasNext="situacaoParecerHasNext"
              :showLabel="true"
              label="Situação de parecer"
              placeholder="Selecione a Situação de parecer"
              class="form--input"
              @search="getSituations($event, true)"
              @load="getSituations($event, false)"
            />

            <!-- DATA DE SUBMISSÃO -->
            <FormInput
              v-model="opinion.dataSubmissao"
              :border="true"
              type="date"
              label="Data de submissão"
              class="form--input"
            />

            <!-- DATA DO PARECER -->
            <FormInput
              v-model="opinion.dataParecer"
              :border="true"
              type="date"
              label="Data do Parecer"
              class="form--input"
            />
          </div>

          <div class="modal-edit--row">
            <!-- BOTÃO DE SALVAR -->
            <Button
              :radius="false"
              class="modal-edit--button"
              @click="saveOpinion()"
            >
              GRAVAR
            </Button>
          </div>
        </Modal>

        <!-- MODAL DE CONFIRMAÇÃO DE REMOÇÃO DE PARECER -->
        <Modal
          v-model="opinion.delete"
          title="REMOVER PARECER"
          class="modal-edit__remove"
        >
          <Paragraph size="60" weight="normal">
            Tem certeza que deseja remover o parecer?
          </Paragraph>

          <div class="modal-edit--row">
            <!-- BOTÃO DE CANCELAR -->
            <Button
              :radius="false"
              color="outline"
              class="modal-edit--button"
              @click="closeModalOpinion()"
            >
              CANCELAR
            </Button>

            <!-- BOTÃO DE DELETAR -->
            <Button
              :radius="false"
              color="danger"
              class="modal-edit--button"
              @click="deleteOpinion()"
            >
              DELETAR
            </Button>
          </div>
        </Modal>
      </template>
    </AdminLayout>
  </div>
</template>

<script>
import AdminLayout from '@/layouts/AdminLayout'
import Heading from '@/components/Heading'
import Paragraph from '@/components/Paragraph'
import FormInput from '@/components/FormInput'
import FormSelect from '@/components/FormSelect'
import FormInputFile from '@/components/FormInputFile'
import Button from '@/components/Button'
import Datatable from '@/components/Datatable'
import Modal from '@/components/Modal'

import { formatDate } from '@/helpers/date'

export default {
  name: 'Opinion',

  components: {
    AdminLayout,
    Heading,
    Paragraph,
    FormInput,
    FormSelect,
    FormInputFile,
    Button,
    Datatable,
    Modal
  },

  data() {
    return {
      /** Dados do parecer */
      apreciacaoId: null,
      caae: null,
      pesquisadorId: null,
      membroCepId: null,
      tipoParecerId: null,
      situacaoParecerId: null,
      dataSubmissao: null,
      dataParecer: null,

      /** Apreciações */
      apreciacaoPage: 0,
      apreciacaoHasNext: false,
      apreciacaoOptions: [],

      /** Pesquisadores */
      pesquisadorPage: 0,
      pesquisadorHasNext: false,
      pesquisadorOptions: [],

      /** Membros do CEP */
      membroCepPage: 0,
      membroCepHasNext: false,
      membroCepOptions: [],

      /** Tipos de pareceres */
      tipoParecerPage: 0,
      tipoParecerHasNext: false,
      tipoParecerOptions: [],

      /** Situação de pareceres */
      situacaoParecerPage: 0,
      situacaoParecerHasNext: false,
      situacaoParecerOptions: [],

      fileCSV: null,

      /** Tabela */
      datatable: {
        gridColumnsUpper: [
          { name: 'id', alias: 'ID' },
          { name: 'caae', alias: 'CAAE', order: true },
          { name: 'parecerista', alias: 'Membro do CEP', order: true },
          { name: 'apreciacao', alias: 'Apreciação', order: true },
          { name: 'pesquisador', alias: 'Pesquisador', order: true },
          { name: 'tipoParecer', alias: 'Tipo', order: true },
          { name: 'situacaoParecer', alias: 'Situação', order: true },
          { name: 'dataSubmissao', alias: 'Submissão', order: true },
          { name: 'dataParecer', alias: 'Data', order: true },
          { name: 'action', alias: 'Ação' }
        ],
        data: [],
        numberElements: 0,
        numberPages: 0,
        page: 1,
        limitPerPage: 20,
        order: null,
        direction: null
      },

      /** Parecer a ser editado */
      opinion: {
        id: null /** ID do parecer */,
        apreciacao: null,
        caae: null,
        pesquisador: null,
        membroCep: null,
        tipoParecer: null,
        situacaoParecer: null,
        dataSubmissao: null,
        dataParecer: null,
        edit: false /** Visibilidade da modal de edição */,
        delete: false /** Visibilidade da modal de remoção */
      }
    }
  },

  created() {
    /** Busca os pareceres */
    this.getOpinions()

    /** Buscar as opções dos campos */
    this.getAppreciations()
    this.getResearchers()
    this.getMembersCep()
    this.getTypes()
    this.getSituations()
  },

  methods: {
    /** Buscar os pareceres */
    getOpinions() {
      this.$opinion
        .get({
          direcao: this.datatable.direction,
          tamanhoPagina: this.datatable.limitPerPage,
          ordenarPor: this.datatable.order,
          pagina: this.datatable.page
        })
        .then((response) => {
          const opinions = response.data.content.map((opinion) => {
            return {
              id: opinion.id,
              caae: opinion.caae,
              cep: opinion.parecerista?.cep?.nome,
              pareceristaId: opinion.parecerista.id,
              parecerista: opinion.parecerista.nome,
              apreciacaoId: opinion.apreciacao.id,
              apreciacao: opinion.apreciacao.nome,
              pesquisadorId: opinion.pesquisador.id,
              pesquisador: opinion.pesquisador.nome,
              tipoParecerId: opinion.tipoParecer.id,
              tipoParecer: opinion.tipoParecer.nome,
              situacaoParecerId: opinion.situacaoParecer?.id,
              situacaoParecer: opinion.situacaoParecer?.nome,
              dataParecer: opinion.dataParecer,
              dataParecerFormat: opinion.dataParecer
                ? formatDate(opinion.dataParecer)
                : null,
              dataSubmissao: opinion.dataSubmissao,
              dataSubmissaoFormat: opinion.dataSubmissao
                ? formatDate(opinion.dataSubmissao)
                : null
            }
          })

          this.datatable.data = opinions
          this.datatable.numberElements = response.data.totalElements
          this.datatable.numberPages = response.data.totalPages
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao apresentar os pareceres.')
        })
    },

    /**
     * Buscar as apreciações
     * @param {String} search: Se for uma pesquisa por um termo
     * @param {Boolean} reload: Se deve recarregar a página
     */
    getAppreciations(search = null, reload = false) {
      /** Paginar */
      this.apreciacaoPage = reload ? 1 : this.apreciacaoPage + 1

      this.$appreciation
        .get({
          nome: search,
          pagina: this.apreciacaoPage,
          tamanhoPagina: 50
        })
        .then((response) => {
          const data = response.data.content.map((apreciacao) => {
            return {
              id: apreciacao.id,
              label: apreciacao.nome
            }
          })

          /** Se há mais dados para paginar */
          this.apreciacaoHasNext = !response.data.last

          /** Atualizar ou acrescentar dados */
          if (reload) {
            this.apreciacaoOptions = data
          } else {
            this.apreciacaoOptions.push(...data)
          }
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao buscar as apreciações.')
        })
    },

    /**
     * Buscar os pesquisadores
     * @param {String} search: Se for uma pesquisa por um termo
     * @param {Boolean} reload: Se deve recarregar a página
     */
    getResearchers(search = null, reload = false) {
      /** Paginar */
      this.pesquisadorPage = reload ? 1 : this.pesquisadorPage + 1

      this.$researcher
        .get({
          nome: search,
          pagina: this.pesquisadorPage,
          tamanhoPagina: 50
        })
        .then((response) => {
          const data = response.data.content.map((pesquisador) => {
            return {
              id: pesquisador.id,
              label: pesquisador.nome
            }
          })

          /** Se há mais dados para paginar */
          this.pesquisadorHasNext = !response.data.last

          /** Atualizar ou acrescentar dados */
          if (reload) this.pesquisadorOptions = data
          else this.pesquisadorOptions.push(...data)
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao buscar os pesquisadores.')
        })
    },

    /**
     * Buscar os membros do cep
     * @param {String} search: Se for uma pesquisa por um termo
     * @param {Boolean} reload: Se deve recarregar a página
     */
    getMembersCep(search = null, reload = false) {
      /** Paginar */
      this.membroCepPage = reload ? 1 : this.membroCepPage + 1

      this.$memberCep
        .get({
          nome: search,
          pagina: this.membroCepPage,
          tamanhoPagina: 50
        })
        .then((response) => {
          const data = response.data.content.map((membroCep) => {
            return {
              id: membroCep.id,
              label: membroCep.nome
            }
          })

          /** Se há mais dados para paginar */
          this.membroCepHasNext = !response.data.last

          /** Atualizar ou acrescentar dados */
          if (search) this.membroCepOptions = data
          else this.membroCepOptions.push(...data)
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao buscar os membros do CEP.')
        })
    },

    /** Limpar campos */
    clearFields() {
      this.apreciacaoId = null
      this.caae = null
      this.pesquisadorId = null
      this.membroCepId = null
      this.tipoParecerId = null
      this.situacaoParecerId = null
      this.dataSubmissao = null
      this.dataParecer = null
      this.opinion.id = null
      this.opinion.apreciacao = null
      this.opinion.caae = null
      this.opinion.pesquisador = null
      this.opinion.membroCep = null
      this.opinion.tipoParecer = null
      this.opinion.situacaoParecer = null
      this.opinion.dataSubmissao = null
      this.opinion.dataParecer = null
      this.fileCSV = null
    },

    /**
     * Buscar os tipos de pareceres
     * @param {String} search: Se for uma pesquisa por um termo
     * @param {Boolean} reload: Se deve recarregar a página
     */
    getTypes(search = null, reload = false) {
      /** Paginar */
      this.tipoParecerPage = reload ? 1 : this.tipoParecerPage + 1

      this.$typeOfOpinion
        .get({
          nome: search,
          pagina: this.tipoParecerPage,
          tamanhoPagina: 50
        })
        .then((response) => {
          const data = response.data.content.map((tipoParecer) => {
            return {
              id: tipoParecer.id,
              label: tipoParecer.nome
            }
          })

          /** Se há mais dados para paginar */
          this.tipoParecerHasNext = !response.data.last

          /** Atualizar ou acrescentar dados */
          if (search) this.tipoParecerOptions = data
          else this.tipoParecerOptions.push(...data)
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao buscar os tipos de pareceres.')
        })
    },

    /**
     * Buscar as situações de pareceres
     * @param {String} search: Se for uma pesquisa por um termo
     * @param {Boolean} reload: Se deve recarregar a página
     */
    getSituations(search = null, reload = false) {
      /** Paginar */
      this.situacaoParecerPage = reload ? 1 : this.situacaoParecerPage + 1

      this.$situationOfOpinion
        .get({
          nome: search,
          pagina: this.situacaoParecerPage,
          tamanhoPagina: 50
        })
        .then((response) => {
          const data = response.data.content.map((situacaoParecer) => {
            return {
              id: situacaoParecer.id,
              label: situacaoParecer.nome
            }
          })

          /** Se há mais dados para paginar */
          this.situacaoParecerHasNext = !response.data.last

          /** Atualizar ou acrescentar dados */
          if (search) this.situacaoParecerOptions = data
          else this.situacaoParecerOptions.push(...data)
        })
        .catch(() => {
          this.$toast.warning(
            'Houve um erro ao buscar as situações de pareceres.'
          )
        })
    },

    /** Criar um parecer */
    createOpinion() {
      this.$opinion
        .create({
          apreciacaoId: this.apreciacaoId?.id,
          caae: this.caae,
          pesquisadorId: this.pesquisadorId?.id,
          pareceristaId: this.membroCepId?.id,
          tipoParecerId: this.tipoParecerId?.id,
          situacaoParecerId: this.situacaoParecerId?.id,
          dataSubmissao: this.dataSubmissao,
          dataParecer: this.dataParecer
        })
        .then(() => {
          /** Feedback de sucesso */
          this.$toast.success('Parecer criado com sucesso!')
        })
        .catch(() => {
          /** Feedback de erro */
          this.$toast.error('Houve um erro ao criar a parecer!')
        })
        .finally(() => {
          this.clearFields()

          /** Recarregar a lista */
          this.getOpinions()
        })
    },

    /** Criar parecer por arquivo CSV */
    uploadFile() {
      const fieldsOpinions = new FormData()
      fieldsOpinions.append('file', this.fileCSV)

      this.$opinion
        .upload(fieldsOpinions)
        .then(() => {
          /** Feedback de sucesso */
          this.$toast.success('Arquivo enviado com sucesso!')
        })
        .catch(() => {
          /** Feedback de erro */
          this.$toast.error('Houve um erro ao enviar o Arquivo!')
        })
        .finally(() => {
          /** Limpar os campos */
          this.clearFields()

          /** Recarregar a lista */
          this.getOpinions()
        })
    },

    /**
     * Paginação
     * @param {Number} page: Número da página selecionada
     */
    paginationCallback(page) {
      this.datatable.page = page
      this.getOpinions()
    },

    /**
     * Ordenação
     * @param {Object} data: Objeto de dados da ordenação
     */
    orderCallback(data) {
      this.datatable.order = data.order
      this.datatable.direction = data.direction
      this.getOpinions()
    },

    /**
     * Pesquisa da tabela
     * @param {Object} data: Objeto de dados da ordenação
     */
    searchCallback(search) {
      this.datatable.page = 1
      this.datatable.search = search
      this.getOpinions()
    },

    /**
     * Exibir a modal de edição de parecer
     * @param {Number} id: Id do parecer
     */
    edit(id) {
      /** Localizar o parecer na listagem */
      const opinion = this.datatable.data.find((item) => item.id === id)

      /** Pegar os dados do parecer e abrir a modal */
      if (opinion) {
        this.opinion.edit = true
        this.opinion.id = opinion.id
        this.opinion.caae = opinion.caae
        this.opinion.apreciacao = {
          id: opinion.apreciacaoId,
          label: opinion.apreciacao
        }
        this.opinion.pesquisador = {
          id: opinion.pesquisadorId,
          label: opinion.pesquisador
        }
        this.opinion.membroCep = {
          id: opinion.pareceristaId,
          label: opinion.parecerista
        }
        this.opinion.tipoParecer = {
          id: opinion.tipoParecerId,
          label: opinion.tipoParecer
        }
        this.opinion.situacaoParecer = {
          id: opinion.situacaoParecerId,
          label: opinion.situacaoParecer
        }
        this.opinion.dataSubmissao = opinion.dataSubmissao
        this.opinion.dataParecer = opinion.dataParecer
      } else {
        this.$toast.warning('Houve um erro ao encontrar o parecer.')
        this.closeModalOpinion()
      }
    },

    /**
     * Confirmar se deseja remover o parecer
     * @param {Number} id: Id do parecer
     */
    dialogOpinion(id) {
      /** Localizar o parecer na listagem */
      const opinion = this.datatable.data.find((item) => item.id === id)

      /** Pegar os dados do parecer e abrir a modal de confirmação */
      if (opinion) {
        this.opinion.delete = true
        this.opinion.id = opinion.id
        this.opinion.caae = opinion.caae
      } else {
        this.$toast.warning('Houve um erro ao encontrar o parecer.')
        this.closeModalOpinion()
      }
    },

    /** Fechar a modal do parecer */
    closeModalOpinion() {
      this.opinion.edit = false
      this.opinion.delete = false
      this.opinion.nome = null
    },

    /** Atualizar o parecer */
    saveOpinion() {
      this.$opinion
        .update(this.opinion.id, {
          caae: this.opinion.caae,
          apreciacaoId: this.opinion.apreciacao.id,
          pesquisadorId: this.opinion.pesquisador.id,
          pareceristaId: this.opinion.membroCep.id,
          tipoParecerId: this.opinion.tipoParecer.id,
          situacaoParecerId: this.opinion.situacaoParecer.id,
          dataSubmissao: this.opinion.dataSubmissao,
          dataParecer: this.opinion.dataParecer
        })
        .then(() => {
          this.$toast.success('Parecer atualizado com sucesso!')
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao atualizar o parecer.')
        })
        .finally(() => {
          /** Fechar a modal */
          this.closeModalOpinion()

          this.clearFields()

          /** Recarregar a listagem */
          this.getOpinions()
        })
    },

    /** Remover o parecer */
    deleteOpinion() {
      this.$opinion
        .delete(this.opinion.id)
        .then(() => {
          this.$toast.success('Parecer removido com sucesso!')
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao remover o parecer.')
        })
        .finally(() => {
          /** Fechar a modal */
          this.closeModalOpinion()

          /** Recarregar a listagem */
          this.getOpinions()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
}
.form {
  display: flex;
  align-items: flex-end;
  background: $gray;
  padding: 7px;
  border-radius: 5px;
  gap: 7px;
  margin-bottom: 25px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  &--input {
    margin: 0px;
  }

  &--button {
    margin: 0px;
  }

  &__edit {
    display: grid;
    gap: 7px;
    grid-template-columns: 1fr 1fr;
  }

  &__csv-file {
    grid-template-columns: 2fr 1fr;
  }
}

.datatable {
  &--link {
    cursor: pointer;
    text-decoration: underline;
  }

  ::v-deep {
    thead th:nth-child(1),
    tr td:nth-child(1) {
      width: 50px;
      text-align: center;
    }

    .datatable--td .paragraph {
      font-size: paragraph-size('paragraph-40');
    }
  }

  &--actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  &--icon {
    color: $primary;
    text-align: center;
    cursor: pointer;

    &__remove {
      color: $danger;
    }
  }
}

/** MODAL DE EDIÇÃO DO PARECER */
.modal-edit {
  ::v-deep {
    .paragraph {
      line-height: 20px;
    }
  }

  &--row {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;
  }

  &--button {
    display: block;
  }

  &__edit {
    ::v-deep {
      .modal-component--content {
        max-width: 600px;
      }
    }
  }

  &__remove {
    ::v-deep {
      .modal-component--content {
        max-width: 500px;
      }
    }
  }
}
</style>
